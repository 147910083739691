import { css } from 'lit';

export const anchors = css`
  a {
    color: #444;
    display: block;
    font-family: var(--serif-font);
    display: block;
    font-size: 1.1em;
    line-height: 1em;
  }

  a:hover {
    color: var(--active-text-color);
    text-decoration: underline;
  }
`