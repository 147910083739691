const _temp0 = require("./a-campaign-promise-scorecard.json");
const _temp1 = require("./about-us.json");
const _temp2 = require("./additional-reading.json");
const _temp3 = require("./directory.json");
const _temp4 = require("./policy-promises.json");
const _temp5 = require("./political-graveyard.json");
const _temp6 = require("./public-policy-101.json");
const _temp7 = require("./rahm-emanuel-corporate-errand-boy-mayor-of-chicago.json");
const _temp8 = require("./the-dangerously-informed-reality-of-mayor-rahm-emanuel.json");
module.exports = {
  "a-campaign-promise-scorecard": _temp0,
  "about-us": _temp1,
  "additional-reading": _temp2,
  "directory": _temp3,
  "policy-promises": _temp4,
  "political-graveyard": _temp5,
  "public-policy-101": _temp6,
  "rahm-emanuel-corporate-errand-boy-mayor-of-chicago": _temp7,
  "the-dangerously-informed-reality-of-mayor-rahm-emanuel": _temp8
}