import { html } from 'lit';
import '../components/NavPrimary';
import '../components/PageHeader';
import '../components/BookplateArticle';
import '../components/ContentPrimary';
import '../components/FeaturedArticle';
import '../components/SidebarWidget';
import '../components/SidebarWidgets';
import '../components/SinglePage';
import '../components/WidgetContentList';

export default ({ pageContext, siteTitle, pageTitle, content }) => {
  const {
    articles,
    bookplateArticle,
    categories,
    contentType,
    subPages
  } = JSON.parse(content);
  
  const showBookplateArticle = bookplateArticle ? html`<bookplate-article content=${JSON.stringify(bookplateArticle)}></bookplate-article>` : "";

  const showCategoriesWidget = categories ? html`
<sidebar-widget class="categories" title="Categories">
  <widget-content-list content=${JSON.stringify(categories)} contentType="${contentType}" widgetType="category">
</sidebar-widget>` : "";

  const showSubPagesWidget = subPages ? html`
<sidebar-widget class="sub-pages" title="${subPages.title}">
  <widget-content-list content=${JSON.stringify(subPages.content)} rootPath=${subPages.rootPath}>
</sidebar-widget>` : "";

  return html`
<page-header></page-header>
<nav-primary pageContext=${pageContext}></nav-primary>
${showBookplateArticle}
<content-primary>
  <single-page content=${JSON.stringify(articles)}></single-page>
  <sidebar-widgets>
    ${showCategoriesWidget}
    ${showSubPagesWidget}
  </sidebar-widgets>
</content-primary>
`;}