const _temp0 = require("../education/page-1.json");

const _temp1 = require("../environment/page-1.json");

const _temp2 = require("../financial/page-1.json");

module.exports = {
  "education":   {
    "page-1": _temp0
  },
  "environment":   {
    "page-1": _temp1
  },
  "financial":   {
    "page-1": _temp2
  }
}