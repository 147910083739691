import { getRoutes } from "./get-routes";

export const getPageContext = (context) => {
  const { path, pathname, params, parentPath, router } = context;
  
  return JSON.stringify({
    path,
    pathname,
    parentPath,
    params,
    routes: router.root.children.filter(c => !c.skip).map(getRoutes)
  });
}