import { css } from 'lit';

export const widgets = css`
:host {
  display: block;
  margin-bottom: 1em;
  padding-top: .5em;
}

a {
  font-size: 1em;
  line-height: 1.6em;
}

header {
  background: url(/images/interface/mt-side-title-bar.png) top left/100% auto repeat-y;
  background-size: 100%;
  display: flex;
}

header img {
  margin: 1% 5% 0px 2%;
  width: 10%;
}

li {
  margin-bottom: .5em;
}

li::marker {
  color: var(--list-marker-color);
}

ul {
  margin: 0 0 0 7.3333333%;
  padding: 0;
}

.entry-content {
  background: url(/images/interface/sidebar-wht.png) top left repeat-y;
  padding: .5em 9.33262% .5em 2.33333%;
}

.entry-content p {
  margin: 0 0 0 7.3333333%;
}

.entry-title {
  font: var(--serif-font);
  line-height: 1.131em;
  margin: 0;
  padding: .5em 9.33262% .5em 2.33333%;
  text-transform: uppercase;
  width: 83.33405%;
}
`;