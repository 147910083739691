import { fetchData } from "./fetch-data";
import { renderPage } from "./render-page";
import single from '../layouts/single';
// @ts-ignore
import * as reportCards from '../report-cards/*.json';
// @ts-ignore
import * as pages from '../pages/*.json';
import { reportCardsSubPages } from '../report-cards/sub-pages';

export const fetchReportCard = context => fetchData(new URL(reportCards[context.params.name], import.meta.url), {
  ...context,
  contentType: "report-cards"
})
.then(async data => {
  const categories = await fetchData(new URL("../policy-promises/categories.json", import.meta.url));
  const bookplateArticle = await fetchData(new URL(pages["policy-promises"]));

  return renderPage(
    single, {
      articles: data,
      bookplateArticle,
      categories,
      contentType: "policy-promises",
      subPages: reportCardsSubPages
    },
    {
      ...context,
      contentType: "report-cards"
    });
});
