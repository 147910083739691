const _temp0 = require("./categories.json");
const _temp1 = require("./chicago-first-policy.json");
const _temp2 = require("./economic-development-tax-policy.json");
const _temp3 = require("./education-policy.json");
const _temp4 = require("./financial.json");
const _temp5 = require("./greening-the-supply-chain.json");
const _temp6 = require("./paperless-transparency.json");
const _temp7 = require("./pension-policy.json");
const _temp8 = require("./reverse-auctioning.json");
const _temp9 = require("./tax-swaps.json");
const _temp10 = require("./wmb-wme-reform.json");
module.exports = {
  "categories": _temp0,
  "chicago-first-policy": _temp1,
  "economic-development-tax-policy": _temp2,
  "education-policy": _temp3,
  "financial": _temp4,
  "greening-the-supply-chain": _temp5,
  "paperless-transparency": _temp6,
  "pension-policy": _temp7,
  "reverse-auctioning": _temp8,
  "tax-swaps": _temp9,
  "wmb-wme-reform": _temp10
}