import { fetchData } from "./fetch-data";
import { renderPage } from "./render-page";
import list from '../layouts/list';
// @ts-ignore
import * as categoryPagination from '../policy-promises/category/*/pagination/*.json';

export const fetchPolicyPromiseCategoryPages = (context, url) => {
  const categorySlug = context.path.replaceAll('/', '');
  
  return fetchData(new URL(url, import.meta.url), context)
  .then(async data => {
    const categories = await fetchData(new URL("../policy-promises/categories.json", import.meta.url));
    const currentPage = context.path.startsWith('/page-') ? Number(context.path.replace('/page-', '')) : 1;
    const categoryArticle = categories.find((c) => c.slug === categorySlug)

    return renderPage(
      list, {
        articles: data,
        categories,
        currentPage,
        bookplateArticle: categoryArticle,
        pagination: categoryPagination[categorySlug]
      },
      context,
    );
  });
}
