import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';

@customElement('content-primary')
export class ContentPrimary extends LitElement {
  @property() content = [];

  static get styles() {
    return [
      css`
        :host {
          display: grid;
          grid-template-columns: 70% 30%;
          position:relative;
          width: 100%;
        }
      `
    ];
  }

  render() {
    return html`
      <slot></slot>
    `;
  }
}