const _temp0 = require("./page-1.json");
const _temp1 = require("./page-10.json");
const _temp2 = require("./page-11.json");
const _temp3 = require("./page-12.json");
const _temp4 = require("./page-13.json");
const _temp5 = require("./page-14.json");
const _temp6 = require("./page-15.json");
const _temp7 = require("./page-16.json");
const _temp8 = require("./page-17.json");
const _temp9 = require("./page-18.json");
const _temp10 = require("./page-19.json");
const _temp11 = require("./page-2.json");
const _temp12 = require("./page-20.json");
const _temp13 = require("./page-21.json");
const _temp14 = require("./page-22.json");
const _temp15 = require("./page-23.json");
const _temp16 = require("./page-24.json");
const _temp17 = require("./page-25.json");
const _temp18 = require("./page-26.json");
const _temp19 = require("./page-27.json");
const _temp20 = require("./page-28.json");
const _temp21 = require("./page-29.json");
const _temp22 = require("./page-3.json");
const _temp23 = require("./page-30.json");
const _temp24 = require("./page-31.json");
const _temp25 = require("./page-32.json");
const _temp26 = require("./page-33.json");
const _temp27 = require("./page-34.json");
const _temp28 = require("./page-35.json");
const _temp29 = require("./page-36.json");
const _temp30 = require("./page-37.json");
const _temp31 = require("./page-38.json");
const _temp32 = require("./page-39.json");
const _temp33 = require("./page-4.json");
const _temp34 = require("./page-40.json");
const _temp35 = require("./page-41.json");
const _temp36 = require("./page-42.json");
const _temp37 = require("./page-43.json");
const _temp38 = require("./page-44.json");
const _temp39 = require("./page-45.json");
const _temp40 = require("./page-46.json");
const _temp41 = require("./page-47.json");
const _temp42 = require("./page-48.json");
const _temp43 = require("./page-49.json");
const _temp44 = require("./page-5.json");
const _temp45 = require("./page-6.json");
const _temp46 = require("./page-7.json");
const _temp47 = require("./page-8.json");
const _temp48 = require("./page-9.json");
module.exports = {
  "page-1": _temp0,
  "page-10": _temp1,
  "page-11": _temp2,
  "page-12": _temp3,
  "page-13": _temp4,
  "page-14": _temp5,
  "page-15": _temp6,
  "page-16": _temp7,
  "page-17": _temp8,
  "page-18": _temp9,
  "page-19": _temp10,
  "page-2": _temp11,
  "page-20": _temp12,
  "page-21": _temp13,
  "page-22": _temp14,
  "page-23": _temp15,
  "page-24": _temp16,
  "page-25": _temp17,
  "page-26": _temp18,
  "page-27": _temp19,
  "page-28": _temp20,
  "page-29": _temp21,
  "page-3": _temp22,
  "page-30": _temp23,
  "page-31": _temp24,
  "page-32": _temp25,
  "page-33": _temp26,
  "page-34": _temp27,
  "page-35": _temp28,
  "page-36": _temp29,
  "page-37": _temp30,
  "page-38": _temp31,
  "page-39": _temp32,
  "page-4": _temp33,
  "page-40": _temp34,
  "page-41": _temp35,
  "page-42": _temp36,
  "page-43": _temp37,
  "page-44": _temp38,
  "page-45": _temp39,
  "page-46": _temp40,
  "page-47": _temp41,
  "page-48": _temp42,
  "page-49": _temp43,
  "page-5": _temp44,
  "page-6": _temp45,
  "page-7": _temp46,
  "page-8": _temp47,
  "page-9": _temp48
}