import { getPageContext } from './get-page-context';
import { render } from 'lit';
import { siteTitle } from './site-title';

export const renderPage = (layout, data, context) => 
  render(layout({
    pageContext: getPageContext(context),
    siteTitle,
    pageTitle: data.title,
    content: JSON.stringify(data)
  }, context), document.body);