import { css } from 'lit';

export const articlesStyles = css`
article {
  margin-bottom: 1em;
  max-width: 100%;
  padding-top: .5em;
}

h2 {
  margin: 0;
  width: 59%;
}

img {
  display: inline-block;
  max-height: 100%;
  max-width: 100%;
}

header h2 a {
  font-size: 1em;
  line-height: 1.3em;
}

.excerpt:first-letter {
  font-size: 1.3em;
}

.article-body {
  width: 64%;
}

.article-info {
  display: block;
  font-size: 1em;
}

.entry-content {
  padding: 1em 0;
}

.read-more {
  font-size: 1.1em;
  white-space: nowrap;
}
`;