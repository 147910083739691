import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { anchors } from '../css/anchors.css';
import { articleStyles } from '../css/article.css';
import { articlesStyles } from '../css/articles.css';
import { typography } from "../css/type.css";
import categoryImages from "../images/category/*.png";

const baseURL = import.meta.url;
const redStar = new URL("../images/interface/mt-star-red.png", import.meta.url);
const backgroundBanner = new URL("../images/interface/content-title-bar.png", import.meta.url);

const articleTemplate = (article, path, contentType) => {
  const { author, categories, createDate, editDate, excerpt, slug, title } = article;

  const cd = new Date(createDate);
  const cdyear = cd.getUTCFullYear();
  const cdmonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(cd);
  const cddate = cd.getDate();
  const ed = new Date(editDate);
  const edyear = ed.getUTCFullYear();
  const edmonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(ed);
  const eddate = ed.getDate();

  const pathPrefix = `/${contentType}`;// path === '/' ? '/articles' : path;
  const currentCategory = (categories && categories.length > 0 && categories[0][1]) || "";
  const { name: categoryName, slug: categorySlug } = currentCategory;
  
  const resolveCurrentCategoryImageUrl = (image, type) => 
    categoryImages[`${image}-${type}`]
      ? categoryImages[`${image}-${type}`]
      : categoryImages["mt-star-black"];

  const imageSelector = contentType === "political-graveyard" ? "political-graveyard" : categorySlug;
  const currentCategoryImageUrl = resolveCurrentCategoryImageUrl(imageSelector, "icon");
  return html`
<article>
  <header>
    <span class="legend">
    <a href="${pathPrefix}/category/${categorySlug}">${categoryName}</a>
    </span>
    <h2><a href="${pathPrefix}/${slug}">${title}</a></h2>
    <img src=${ redStar } alt="Sidebar Widget Star">
  </header>
  <div class="entry-content">
    <div class="legend">
      <a href="${pathPrefix}/category/${categorySlug}"><img src="${currentCategoryImageUrl}" alt="${categoryName || "Default"} Icon" /></a>
    </div>
    <div class="article-body summary">
      <p class="article-info">by ${author} on ${cdyear}/${cdmonth}/${cddate}<br>(last edited on: ${edyear}/${edmonth}/${cddate})</p>
      <p class="excerpt">${excerpt}</p>
      <p class="read-more">
          <a href="${pathPrefix}/${slug}">Read More</a>
      </p>
    </div>
  </div>
</article>
`;
}

@customElement('article-list')
export class ArticleList extends LitElement {
  @property() content = "";
  @property() path = "";
  @property() contentType = "";

  static get styles() {
    return [
      anchors,
      articleStyles,
      articlesStyles,
      typography,
      css`
.legend {
  padding: 6px 6% 0 2%;
  text-transform: uppercase;
  width: 22%;
}

header .legend a {
  color: var(--text-color);
  font-size: 1em;
}

.entry-content .legend {
  padding: 0 4%;
}
`];
  }

  render() {
    const content = JSON.parse(this.content);

    return html`
      ${content.map(a => articleTemplate(a, this.path, this.contentType))}
    `;
  }
}