export const fetchData = async (path, context = { params: { entries: []}}) => {
  let dynamicPath = path; 
  
  if (path.href.includes("/:")){
    context.params.entries.forEach(([k, v]) => dynamicPath = dynamicPath.replace(`:${k}`, `${v}`));
  }

  let response = await fetch(dynamicPath);
  let data = await response.json();
  return data;
}