const _temp0 = require("./page-1.json");
const _temp1 = require("./page-10.json");
const _temp2 = require("./page-2.json");
const _temp3 = require("./page-3.json");
const _temp4 = require("./page-4.json");
const _temp5 = require("./page-5.json");
const _temp6 = require("./page-6.json");
const _temp7 = require("./page-7.json");
const _temp8 = require("./page-8.json");
const _temp9 = require("./page-9.json");
module.exports = {
  "page-1": _temp0,
  "page-10": _temp1,
  "page-2": _temp2,
  "page-3": _temp3,
  "page-4": _temp4,
  "page-5": _temp5,
  "page-6": _temp6,
  "page-7": _temp7,
  "page-8": _temp8,
  "page-9": _temp9
}