import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { anchors } from '../css/anchors.css';
import { articlesStyles } from '../css/articles.css';
import { typography } from "../css/type.css";
import interfaceImages from "../images/interface/*.png";

@customElement('featured-article')
export class FeaturedArticle extends LitElement {
  @property() content = "";

  static get styles() {
    return [
      anchors,
      articlesStyles,
      typography,
      css`
:host {
  display: block;
  padding: 3%;
  width: 94%;
}

figure {
  width: 35%;
}

figure img {
  width: 78%;
}

figure .failover {
  padding: 0 0 0 4em;
}

p {
  line-height: 1.4em;
}

.article-body {
  width: 65%;
}

.article-info {
  width: 100%;
}

.entry-content {
  display: flex;
  padding-bottom: 0;
}

.entry-title {
  font-size: 1.9em;
  line-height: 1.35em;
  margin: 0;
  width: 100%;
}

.read-more {
  margin-bottom: 0;
}
      `
    ];
  }

  render() {
    const article = JSON.parse(this.content);
    const { author, categories, createDate, editDate, excerpt, slug, title } = article;

    const cd = new Date(createDate);
    const cdyear = cd.getUTCFullYear();
    const cdmonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(cd);
    const cddate = cd.getDate();
    const ed = new Date(editDate);
    const edyear = ed.getUTCFullYear();
    const edmonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(ed);
    const eddate = ed.getDate();

    // FIXME :: this needs to get used to pull in a rich category object
    const currentCategory = (categories && categories[0]) || "";
    const currentCategoryName = currentCategory;
    const currentCategoryImageUrl = (image, type) => image
      ? interfaceImages[`${image}-${type}`]
      : interfaceImages["mt-star-black"];

    return html`
<h2 class="entry-title">
  <a href="/articles/${slug}">${title}</a>
</h2>

<div class="entry-content">
  <div class="article-body">
    <p class="article-info">
      by ${author} on ${cdyear}/${cdmonth}/${cddate}<br>(last edited on: ${edyear}/${edmonth}/${cddate})
    </p>
    <p class="excerpt">${excerpt}</p>
    <p class="read-more">
      <a href="/articles/${slug}">Read More</a>
    </p>
  </div>
  <figure>
    <img class=${categories.length > 0 ? "" : "failover"} src="${currentCategoryImageUrl(currentCategory, "plate")}" alt="${currentCategoryName || "Default"} Book Plate" />
  </figure>
</div>
    `;
  }
}