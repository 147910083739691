import { fetchData } from "./fetch-data";
import { renderPage } from "./render-page";
import single from '../layouts/single';

// @ts-ignore
import * as articles from '../articles/*.json';
// @ts-ignore
import * as articlePagination from '../articles/pagination/*.json';

export const fetchArticle = context => fetchData(new URL(articles[context.params.name], import.meta.url), context)
  .then(async data => {
    const categories = await fetchData(new URL("../articles/categories.json", import.meta.url));
    const candidates = await fetchData(new URL("../articles/candidates.json", import.meta.url));
    const bookplateArticle = data.categories.length > 0 ? data.categories[0][1] : undefined;

    return renderPage(
      single, {
        articles: data,
        bookplateArticle,
        candidates,
        categories,
        currentPage: 1,
        pagination: articlePagination
      },
      context
    );
  });
