const _temp0 = require("./accountability-icon.png");
const _temp1 = require("./accountability-plate.png");
const _temp2 = require("./candidate-watch-icon.png");
const _temp3 = require("./candidate-watch-plate.png");
const _temp4 = require("./champions-icon.png");
const _temp5 = require("./champions-plate.png");
const _temp6 = require("./directory-icon.png");
const _temp7 = require("./directory-plate.png");
const _temp8 = require("./education-icon.png");
const _temp9 = require("./education-plate.png");
const _temp10 = require("./environment-icon.png");
const _temp11 = require("./environment-plate.png");
const _temp12 = require("./familysupport-icon.png");
const _temp13 = require("./financial-icon.png");
const _temp14 = require("./financial-plate.png");
const _temp15 = require("./hackfest-icon.png");
const _temp16 = require("./hackfest-plate.png");
const _temp17 = require("./homework-icon.png");
const _temp18 = require("./homework-plate.png");
const _temp19 = require("./infotech-icon.png");
const _temp20 = require("./intergov-icon.png");
const _temp21 = require("./jack-icon.png");
const _temp22 = require("./jack-plate.png");
const _temp23 = require("./machine-shop-icon.png");
const _temp24 = require("./machine-shop-plate.png");
const _temp25 = require("./mt-star-black.png");
const _temp26 = require("./mt-star-red.png");
const _temp27 = require("./mt-watchdog-icon.png");
const _temp28 = require("./mt-watchdog-plate.png");
const _temp29 = require("./policy-promises-icon.png");
const _temp30 = require("./policy-promises-plate.png");
const _temp31 = require("./political-arts-icon.png");
const _temp32 = require("./political-arts-plate.png");
const _temp33 = require("./political-graveyard-icon.png");
const _temp34 = require("./political-graveyard-plate.png");
const _temp35 = require("./public-policy-101-icon.png");
const _temp36 = require("./public-policy-101-plate.png");
const _temp37 = require("./rants-icon.png");
const _temp38 = require("./rants-plate.png");
const _temp39 = require("./shadow-icon.png");
const _temp40 = require("./shadow-plate.png");
const _temp41 = require("./spotlight-icon.png");
const _temp42 = require("./spotlight-plate.png");
const _temp43 = require("./transportation-icon.png");
const _temp44 = require("./view-from-washington-icon.png");
const _temp45 = require("./view-from-washington-plate.png");
module.exports = {
  "accountability-icon": _temp0,
  "accountability-plate": _temp1,
  "candidate-watch-icon": _temp2,
  "candidate-watch-plate": _temp3,
  "champions-icon": _temp4,
  "champions-plate": _temp5,
  "directory-icon": _temp6,
  "directory-plate": _temp7,
  "education-icon": _temp8,
  "education-plate": _temp9,
  "environment-icon": _temp10,
  "environment-plate": _temp11,
  "familysupport-icon": _temp12,
  "financial-icon": _temp13,
  "financial-plate": _temp14,
  "hackfest-icon": _temp15,
  "hackfest-plate": _temp16,
  "homework-icon": _temp17,
  "homework-plate": _temp18,
  "infotech-icon": _temp19,
  "intergov-icon": _temp20,
  "jack-icon": _temp21,
  "jack-plate": _temp22,
  "machine-shop-icon": _temp23,
  "machine-shop-plate": _temp24,
  "mt-star-black": _temp25,
  "mt-star-red": _temp26,
  "mt-watchdog-icon": _temp27,
  "mt-watchdog-plate": _temp28,
  "policy-promises-icon": _temp29,
  "policy-promises-plate": _temp30,
  "political-arts-icon": _temp31,
  "political-arts-plate": _temp32,
  "political-graveyard-icon": _temp33,
  "political-graveyard-plate": _temp34,
  "public-policy-101-icon": _temp35,
  "public-policy-101-plate": _temp36,
  "rants-icon": _temp37,
  "rants-plate": _temp38,
  "shadow-icon": _temp39,
  "shadow-plate": _temp40,
  "spotlight-icon": _temp41,
  "spotlight-plate": _temp42,
  "transportation-icon": _temp43,
  "view-from-washington-icon": _temp44,
  "view-from-washington-plate": _temp45
}