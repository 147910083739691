import { html } from 'lit';
import '../components/NavPrimary';
import '../components/PageHeader';
import '../components/BookplateArticle';
import '../components/ArticleList';
import '../components/ContentPrimary';
import '../components/FeaturedArticle';
import '../components/NavPagination';
import '../components/SidebarWidget';
import '../components/SidebarWidgets';
import '../components/WidgetContentList';

export default ({ pageContext, siteTitle, pageTitle, content }, context) => {
  const {
    articles,
    bookplateArticle,
    candidates,
    categories,
    currentPage,
    featured,
    pagination,
    subPages
  } = JSON.parse(content);
  
  const { path, pathname, baseURL } = JSON.parse(pageContext);
  const { contentType } = context;

  const showBookplateArticle = bookplateArticle ? html`<bookplate-article content=${JSON.stringify(bookplateArticle)}></bookplate-article>` : "";
  const showFeaturedArticle = featured ? html`<featured-article content=${JSON.stringify(featured)}></featured-article>` : "";
  
  const showCategoriesWidget = categories ? html`
<sidebar-widget class="categories" title="Categories">
  <widget-content-list content=${JSON.stringify(categories)} contentType="${contentType}" widgetType="category">
</sidebar-widget>` : "";
  
  const showCandidatesWidget = candidates ? html`
<sidebar-widget class="candidates" title="Candidates">
  <widget-content-list content=${JSON.stringify(candidates)} contentType="${contentType}" widgetType="candidate">
</sidebar-widget>` : "";

  const showSubPagesWidget = subPages ? html`
<sidebar-widget class="sub-pages" title="${subPages.title}">
  <widget-content-list content=${JSON.stringify(subPages.content)} contentType="${contentType}" widgetType="sub-pages" rootPath="${subPages.rootPath}">
</sidebar-widget>` : "";

  return html`
<page-header></page-header>
<nav-primary pageContext=${pageContext}></nav-primary>
${showFeaturedArticle || showBookplateArticle}
<content-primary>
  <article-list content=${JSON.stringify(articles)} path=${pathname} contentType=${contentType}></article-list>
  <sidebar-widgets>
    ${showCategoriesWidget}
    ${showCandidatesWidget}
    ${showSubPagesWidget}
  </sidebar-widgets>
</content-primary>
<nav-pagination currentPage=${currentPage} currentPath=${pathname} pages=${JSON.stringify(pagination)} contentType=${contentType}>
`;}