import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { anchors } from '../css/anchors.css.ts';
import { navStyles } from '../css/nav.css.ts';

@customElement('nav-list')
export class NavList extends LitElement {
  @property({type: String}) currentPath = '';
  @property({type: String}) pathName = '';
  @property() routes = '';

  static get styles() {
    return [
      anchors,
      navStyles,
      css`
        :host {
          width: 100%;  
        }

        ul {
          display: flex;
          padding: 0;
          text-align: center;
        }
        
        li {
          flex: 1;
          list-style: none;
        }

        li.active a {
          color: var(--active-text-color);
        }

        li:first-of-type {
          margin: 0 -30px 0 -15px;
        }

        li:last-of-type {
          margin: 0 -30px 0 -30px;
        }
      `
    ];
  }

  render() {
    const routes = JSON.parse(this.routes);
    const excludeList = ['articles', 'article pagination']

    return Array.isArray(routes) ? html`
      <ul>
        ${routes
          .filter(r => !excludeList.includes(r.name))
          .map(r => {
          return html`
          <li class="${r.path === window.location.pathname || (r.path !== "/" && window.location.pathname.startsWith(r.path)) ? "active" : ""}">
            <a href="${r.path}">${r.name}</a>
          </li>
        `})}
      </ul>
    ` : ``
  }
}
