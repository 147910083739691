const _temp0 = require("./13-new-aldermen-1-new-mayor-reform.json");
const _temp1 = require("./2012-veepstakes.json");
const _temp2 = require("./2013-shoulda-coulda-woulda-resolutions.json");
const _temp3 = require("./9-11-is-a-joke.json");
const _temp4 = require("./a-13-million-dollar-corporate-hold-up.json");
const _temp5 = require("./a-bankster-reunion.json");
const _temp6 = require("./a-beginners-guide-to-the-fiscal-cliff-part-1.json");
const _temp7 = require("./a-beginners-guide-to-the-fiscal-cliff.json");
const _temp8 = require("./a-blueprint-for-serfdom.json");
const _temp9 = require("./a-brief-history-a-small-mammal-with-a-big-dream.json");
const _temp10 = require("./a-broken-promise-on-education-part-i-of-ii.json");
const _temp11 = require("./a-broken-promise-on-education-part-ii-of-ii.json");
const _temp12 = require("./a-broken-system-in-interesting-times.json");
const _temp13 = require("./a-chance-to-walk-to-the-talk.json");
const _temp14 = require("./a-comparative-story.json");
const _temp15 = require("./a-conspiracy-of-common-interests.json");
const _temp16 = require("./a-convergence-of-interests.json");
const _temp17 = require("./a-cynical-rahm-finds-jesus-and-earns-odins-wrath-on-the-road-to-re-election.json");
const _temp18 = require("./a-devils-standard.json");
const _temp19 = require("./a-fair-deal.json");
const _temp20 = require("./a-fight-to-watch.json");
const _temp21 = require("./a-final-nato-media-rant.json");
const _temp22 = require("./a-glimpse-into-our-mayors-soul.json");
const _temp23 = require("./a-guy-that-cant-talk-lectures.json");
const _temp24 = require("./a-housing-victory-for-restorative-justice.json");
const _temp25 = require("./a-jobless-recovery-is-not-a-recovery.json");
const _temp26 = require("./a-little-thing-on-tifs.json");
const _temp27 = require("./a-map-without-values.json");
const _temp28 = require("./a-movement-speech.json");
const _temp29 = require("./a-nation-in-trouble.json");
const _temp30 = require("./a-neoliberal-education.json");
const _temp31 = require("./a-picture-paints-10000-words.json");
const _temp32 = require("./a-political-reality-check.json");
const _temp33 = require("./a-public-policy-primer-on-the-emanuel-administration.json");
const _temp34 = require("./a-retrospective-on-mayor-emanuel.json");
const _temp35 = require("./a-sanity-based-venn-diagram-why-bernie-or-bust-wont-move-the-democrats-or-t.json");
const _temp36 = require("./a-scoop-of-non-vanilla.json");
const _temp37 = require("./a-simple-truth.json");
const _temp38 = require("./a-striking-context-rahm-vs.-the-ctu.json");
const _temp39 = require("./a-tale-of-two-romneys.json");
const _temp40 = require("./a-thin-blue-mirage.json");
const _temp41 = require("./a-time-before-the-barricades.json");
const _temp42 = require("./a-time-of-scapegoats-or-solutions.json");
const _temp43 = require("./a-trajectory-of-cruelty.json");
const _temp44 = require("./a-view-from-washington-a-convergence-of-interests.json");
const _temp45 = require("./a-view-from-washington-a-humane-society.json");
const _temp46 = require("./a-view-from-washington-the-common-public-good-or-ucommon-private-violence-a.json");
const _temp47 = require("./a-weak-democracy-yields-a-weaker-debate.json");
const _temp48 = require("./abdon-pallasch-loses-the-context-key.json");
const _temp49 = require("./absurdity-in-public-policy.json");
const _temp50 = require("./adventures-in-privatized-contracting.json");
const _temp51 = require("./after-two-years-ig-ferguson-discovers-the-m-wbe-program-is-corrupt.json");
const _temp52 = require("./agenda-narrative-the-grassroots-collaboratives-challenge.json");
const _temp53 = require("./agents-of-darkness.json");
const _temp54 = require("./ald-munoz-attempt-to-restore-democracy-to-chicago.json");
const _temp55 = require("./ald.-bob-and-wee-willie-wilson-a-study-in-neo-liberal-supremacy.json");
const _temp56 = require("./aldermanic-prerogatives-in-peril.json");
const _temp57 = require("./alec-rahm-a-public-private-partnership.json");
const _temp58 = require("./alejandro-escalona-needs-to-stop-giving-journalism-lectures-and-do-some-jou.json");
const _temp59 = require("./all-the-canaries-are-dying.json");
const _temp60 = require("./american-masada-where-aggrievement-meets-magical-thinking-and-aggrievement.json");
const _temp61 = require("./an-alderman-with-values.json");
const _temp62 = require("./an-evil-zero-sum-gain.json");
const _temp63 = require("./an-existential-threat-to-democracy.json");
const _temp64 = require("./an-honest-discussion-of-the-public-good.json");
const _temp65 = require("./an-idiots-debate.json");
const _temp66 = require("./anatomy-of-a-crime.json");
const _temp67 = require("./angrier-birds.json");
const _temp68 = require("./angry-at-the-wrong-people.json");
const _temp69 = require("./another-day-we-need-a-dollar-the-candidates-on-job-creation.json");
const _temp70 = require("./another-evening-of-presidential-disappointment.json");
const _temp71 = require("./another-mechanism-to-loot-the-public.json");
const _temp72 = require("./arent-city-workers-taxpayers.json");
const _temp73 = require("./around-emanuel-land-in-101-days.json");
const _temp74 = require("./austerity-follies-rahms-cta.json");
const _temp75 = require("./austerity-in-a-land-of-plenty.json");
const _temp76 = require("./back-in-the-fold.json");
const _temp77 = require("./bad-foundation-mojo.json");
const _temp78 = require("./bad-structure-austeritys-reach.json");
const _temp79 = require("./banana-government-theater.json");
const _temp80 = require("./barack-h.-obama-2012-state-of-the-union-drinking-game.json");
const _temp81 = require("./be-a-citizen-no-ones-coming-to-save-us.json");
const _temp82 = require("./benghazi-smoke-screen.json");
const _temp83 = require("./big-business-now-owns-the-city-what-to-look-for.json");
const _temp84 = require("./blackest-day-darkest-night-the-death-of-the-public-trust.json");
const _temp85 = require("./budget-rant.json");
const _temp86 = require("./budgeting-in-the-dark.json");
const _temp87 = require("./budgets-are-moral-documents.json");
const _temp88 = require("./building-the-infrastructure-for-privatization.json");
const _temp89 = require("./calamitys-equation.json");
const _temp90 = require("./campaign-treasure-hunters.json");
const _temp91 = require("./can-a-possibly-criminal-incompetent-agency-be-half-right.json");
const _temp92 = require("./candidates.json");
const _temp93 = require("./casino-madness.json");
const _temp94 = require("./casinos-bankers-bad-crony-public-policy.json");
const _temp95 = require("./cassand.json");
const _temp96 = require("./cassandra-on-the-first-debate-coverage.json");
const _temp97 = require("./categories.json");
const _temp98 = require("./ceo-mittens-doesnt-know-how-the-economy-works.json");
const _temp99 = require("./champions-of-the-city-save-our-schools.json");
const _temp100 = require("./champions-of-the-city-ue-local-1110-stands-up-for-itself.json");
const _temp101 = require("./champions-of-the-city-uic-professors-become-unruly.json");
const _temp102 = require("./charm-offensive.json");
const _temp103 = require("./charter-schools-devour-the-common-public-good-the-agenda-is-still-running.json");
const _temp104 = require("./chicago-is-still-aint-ready-for-reform-the-chicago-police-department-slips.json");
const _temp105 = require("./chicago-policing-strategy-same-as-it-ever-was-worse-than-you-think.json");
const _temp106 = require("./chicagos-budget-what-the-evil-devised-and-the-stupid-voted-for.json");
const _temp107 = require("./chicagos-i-trust-public-policy-epic-fail.json");
const _temp108 = require("./chicagos-infrastructure-bank-when-foxes-franchise-the-building-guarding-of.json");
const _temp109 = require("./citizen-bad-ass-mick-dumke.json");
const _temp110 = require("./civic-imagination-what-makes-a-solution-to-a-real-problem-practical-or-poss.json");
const _temp111 = require("./civic-vampires-the-rentier-class.json");
const _temp112 = require("./civic-washing-satans-laundry.json");
const _temp113 = require("./class-warfare-as-economic-development-in-rahms-chicago.json");
const _temp114 = require("./climate-destruction-by-the-numbers.json");
const _temp115 = require("./cme-must-pay-to-go.json");
const _temp116 = require("./comcasting-a-spell-over-the-city.json");
const _temp117 = require("./commissioner-chuy-garcia-fights-for-the-constitution.json");
const _temp118 = require("./conserving-democracy.json");
const _temp119 = require("./conserving-democracy1.json");
const _temp120 = require("./context-to-rahms-credit-card-crackdown.json");
const _temp121 = require("./cornered-teachers.json");
const _temp122 = require("./corroded-clockwork.json");
const _temp123 = require("./creating-discipline-the-old-fashioned-way.json");
const _temp124 = require("./credit-card-fraud-and-the-emanuel-administration.json");
const _temp125 = require("./criminal-austerity-gets-an-assist.json");
const _temp126 = require("./crisis-hypothesis-the-undead-hand-of-milton-friedman-kills-government...-ag.json");
const _temp127 = require("./crouching-lurkers.json");
const _temp128 = require("./cry-for-the-children.json");
const _temp129 = require("./da-negotiator-what-next.json");
const _temp130 = require("./daleys-legacy-is-corruption.json");
const _temp131 = require("./dangerous-and-unhealthy-work-conditions-or-625-salary-bombs-await-unions.json");
const _temp132 = require("./dark-wizards-convene.json");
const _temp133 = require("./deceptions-hammer.json");
const _temp134 = require("./decoupled.json");
const _temp135 = require("./deja-vu-all-over-again.json");
const _temp136 = require("./deju-vu-all-over-again.json");
const _temp137 = require("./democracy-and-austerity-cannot-exist.json");
const _temp138 = require("./democracy-is-the-battle-that-never-ends.json");
const _temp139 = require("./democracys-messenger.json");
const _temp140 = require("./demons-among-us.json");
const _temp141 = require("./designated-scapegoat-cha-ceo-lewis-jordan-gets-sacrificed.json");
const _temp142 = require("./dirty-schools-and-filthy-food-at-a-union-busting-profit.json");
const _temp143 = require("./disordering-democracy-a-g8-nato-strategy.json");
const _temp144 = require("./dispatches-from-the-front-lines.json");
const _temp145 = require("./divide-conqueror-and-blame.json");
const _temp146 = require("./divide.-conquer.-rinse.-repeat.-mayor-emanuel-at-work.json");
const _temp147 = require("./donald-trump-stop-defaming-frankensteins-monster.json");
const _temp148 = require("./dont-know-much-about-history-public-education-in-privatized-emanueland-part.json");
const _temp149 = require("./dont-know-much-about-history-public-education-in-privatized-emanueland.json");
const _temp150 = require("./dont-know-much-about-history-public-education-in-privatized-emanueland1.json");
const _temp151 = require("./dont-know-much-about-history-public-education-in-privatized-emanueland2.json");
const _temp152 = require("./eating-education-for-lunch.json");
const _temp153 = require("./education-experts-attempt-the-educate-the-illiterate.json");
const _temp154 = require("./education-public-policy-is-in-a-school-daze.json");
const _temp155 = require("./election-day-and-uncertaintys-challenge.json");
const _temp156 = require("./election-over-a-dangerously-informed-take-on-reality-part-1-of-3.json");
const _temp157 = require("./election-over-a-dangerously-informed-take-on-reality-part-2-of-3.json");
const _temp158 = require("./election-over-a-dangerously-informed-take-on-reality-part-3-of-3.json");
const _temp159 = require("./elections-matter-control-the-state-or-be-consumed-by-it.json");
const _temp160 = require("./emanuel-attacks-teachers...-again.json");
const _temp161 = require("./emanuel-vs.-transparency-ethics.json");
const _temp162 = require("./emanuels-30-day-grade-depends-on-the-questions-not-the-answers.json");
const _temp163 = require("./equal-is-as-equal-does.json");
const _temp164 = require("./ethical-irony.json");
const _temp165 = require("./ethics-and-all.json");
const _temp166 = require("./everyday-armageddon.json");
const _temp167 = require("./evil-in-a-bottle-a-tutorial-on-syria-part-i-of-ii.json");
const _temp168 = require("./evil-sneaky-petty-and-stupid-power-in-emanuel-land-2.json");
const _temp169 = require("./evil-sneaky-petty-and-stupid-power-in-emanuel-land.json");
const _temp170 = require("./failing-upwards-remember-who-gery-chico-is.json");
const _temp171 = require("./fight-joe-moreno-who-knew.json");
const _temp172 = require("./fight-like-a-genius.json");
const _temp173 = require("./fight-them-vote.json");
const _temp174 = require("./fighting-for-something-or-losing-everything.json");
const _temp175 = require("./fighting-over-scraps-in-the-rubble.json");
const _temp176 = require("./figure-head-burke-sock-puppet-oconnor-on-the-4th-amendment.json");
const _temp177 = require("./fixing-schools-but-good.json");
const _temp178 = require("./flash-in-pan-panic.json");
const _temp179 = require("./following-finney.json");
const _temp180 = require("./for-a-better-chicago-other-emanuel-allies-need-an-education-on-education.json");
const _temp181 = require("./for-a-blacker-more-realistic-america.json");
const _temp182 = require("./fortune-telling-on-inauguration-day.json");
const _temp183 = require("./four-reasons-nato-is-a-no-no.json");
const _temp184 = require("./fran-speilman-political-comedy-writer.json");
const _temp185 = require("./fran-spielman-turns-a-6-year-old-into-a-political-puppet.json");
const _temp186 = require("./fran-spielman-turns-stenographer.json");
const _temp187 = require("./from-patronage-to-pinstripes-to-star-chamber-politic.json");
const _temp188 = require("./gambling-you-can-steal-more-with-a-briefcase.json");
const _temp189 = require("./gangster-capitalism-the-civic-federation-provides-mayor-emanuel-cover.json");
const _temp190 = require("./gauntlets-thrown-down-gauges-dropped-gloves-off-and-belled-cats-its-on.json");
const _temp191 = require("./gibberish-manure.json");
const _temp192 = require("./gifts-for-corporations-pain-for-social-service-providers.json");
const _temp193 = require("./give-us-some-issue-part-1.json");
const _temp194 = require("./give-us-some-issues-hackfest-part-2.json");
const _temp195 = require("./goldman-sachs-eats-more-small-businesses.json");
const _temp196 = require("./good-omens.json");
const _temp197 = require("./gop-pledge-drive.json");
const _temp198 = require("./grope-and-change.json");
const _temp199 = require("./half-empty-and-over-full.json");
const _temp200 = require("./half-measures-never-saved-anyone.json");
const _temp201 = require("./hallmarks-of-good-budget-and-taxation-policy-part-1.json");
const _temp202 = require("./high-costs-of-corporate-policing.json");
const _temp203 = require("./high-on-the-mainstream-embankment.json");
const _temp204 = require("./history-is-never-over-stop-looking-for-answers-that-dont-matter.json");
const _temp205 = require("./home.json");
const _temp206 = require("./homeland-security-theater-games.json");
const _temp207 = require("./hot-dog-time.json");
const _temp208 = require("./how-about-a-little-help.json");
const _temp209 = require("./how-dumb-we-supposed-to-be.json");
const _temp210 = require("./i-dont-know-nothing.json");
const _temp211 = require("./i-hate-the-numbers-9-and-11.json");
const _temp212 = require("./i-predict-a-riot-the-candidates-on-public-safety.json");
const _temp213 = require("./i-tire-of-the-20th-century.json");
const _temp214 = require("./i-want-the-news-back.json");
const _temp215 = require("./icirr-is-living-the-dream.json");
const _temp216 = require("./idiot-economic-conversation.json");
const _temp217 = require("./illinois-gov.-race-2014-no-ones-on-your-side.json");
const _temp218 = require("./in-the-wake-of-the-wrecking-ball.json");
const _temp219 = require("./innocence-projects-founder-doesnt-get-a-trial.json");
const _temp220 = require("./invoking-children-to-help-anti-child-education-agenda.json");
const _temp221 = require("./irony-idiocy-and-the-irredeemable-reality-of-our-politics.json");
const _temp222 = require("./it-took-nixon-to-open-china-and-obama-to-close-medicare-medicaid-and-social.json");
const _temp223 = require("./its-about-the-constitution-stupid.json");
const _temp224 = require("./its-all-about-the-buildings.json");
const _temp225 = require("./its-called-a-fact-check-media-people.json");
const _temp226 = require("./its-called-crony-capitalism.json");
const _temp227 = require("./its-either-cicero-or-solon-the-choice-is-coming-sooner-not-later.json");
const _temp228 = require("./its-just-a-heist-you-can-steal-more-with-a-briefcase-than-you-can-with-a-gu.json");
const _temp229 = require("./its-just-money-the-candidates-on-fiscal-policy.json");
const _temp230 = require("./its-my-money.json");
const _temp231 = require("./its-not-about-education-its-about-money.json");
const _temp232 = require("./its-not-about-education-stupid.json");
const _temp233 = require("./its-not-style-its-substance-rahm-vs.-chuy-isnt-about-santa-and-lumps-of-coa.json");
const _temp234 = require("./its-not-the-debt-its-the-plan.json");
const _temp235 = require("./its-the-administration-not-the-amer.json");
const _temp236 = require("./jack-asks-when-does-700-3000.json");
const _temp237 = require("./jack-in-transition.json");
const _temp238 = require("./job-creation-1-style.json");
const _temp239 = require("./jobs-is-it.json");
const _temp240 = require("./jobs-w-justice-holds-first-friday-smackdowns.json");
const _temp241 = require("./judges-referendums-a-tutorial-guide.json");
const _temp242 = require("./katrina-ed.json");
const _temp243 = require("./keeping-count-with-content.json");
const _temp244 = require("./killer-carnivorous-snails-from-france.json");
const _temp245 = require("./king-is-here.json");
const _temp246 = require("./laborers-local-1001-eats-their-young.json");
const _temp247 = require("./laquan-mcdonald-the-public-execution-of-brutal-public-policies.json");
const _temp248 = require("./lawyers-guns-and-money-the-economics-of-public-safety.json");
const _temp249 = require("./let-the-better-policing-begin-one-promise-down.json");
const _temp250 = require("./lets-make-a-deal.json");
const _temp251 = require("./light-will-prevail.json");
const _temp252 = require("./long-live-the-king.json");
const _temp253 = require("./looting-the-public-trust-rahm-opens-the-education-market-wide.json");
const _temp254 = require("./losing-chicagos-commons.json");
const _temp255 = require("./managed-competition-is-the-actual-garbage.json");
const _temp256 = require("./marmoset-supremacy-part-2-balancing-broken-budgets-schools.json");
const _temp257 = require("./marmoset-supremacy-part-3-education-advocacy.json");
const _temp258 = require("./marmoset-supremacy-part-4-shut-up-for-your-own-safety-sir.json");
const _temp259 = require("./marmoset-supremacy-rahms-record-part-1.json");
const _temp260 = require("./maternity-leave.json");
const _temp261 = require("./mayor-emanuel-demands-citizens-pay-a-19.6-million-dollar-corporate-fee.json");
const _temp262 = require("./mayor-emanuel-knows-who-he-is.json");
const _temp263 = require("./mayor-emanuel-opens-an-open-book.json");
const _temp264 = require("./mayor-emanuel-sacrifices-worker-safety-and-cuts-20-million-in-city-services.json");
const _temp265 = require("./mayor-emanuel-the-case-for-four-more-years.json");
const _temp266 = require("./mayor-emanuel-the-city-council-pass-a-police-accountability-law-without-any.json");
const _temp267 = require("./mayor-emanuels-big-blue-con-job-nothing-new-to-see-here-kids-expect-more-vi.json");
const _temp268 = require("./mayor-emanuels-police-promise.json");
const _temp269 = require("./mayor-rahm-emanuel-the-hapless-cabin-boy-to-the-captains-of-cutthroat-indus.json");
const _temp270 = require("./meanwhile-back-in-chicago.json");
const _temp271 = require("./michael-mcconnnell-a-peaceful-force-for-justice.json");
const _temp272 = require("./misdirection-and-privatization.json");
const _temp273 = require("./mister-muzzle-nuzzle.json");
const _temp274 = require("./monumental-trouble.json");
const _temp275 = require("./my-mayor-is-not-going-anywhere.json");
const _temp276 = require("./my-pirate-ship-finally-sets-sail.json");
const _temp277 = require("./myth-busting-unsustainable-policing.json");
const _temp278 = require("./myth-making-in-chicago-part-1.json");
const _temp279 = require("./myth-making-in-chicago-part-2.json");
const _temp280 = require("./no-one-is-coming-to-save-us-but-us.json");
const _temp281 = require("./no-ones-on-our-side.json");
const _temp282 = require("./noreen-ahmed-doesnt-know-enough-about-chicago-communities.json");
const _temp283 = require("./not-so-super-tuesday-report.json");
const _temp284 = require("./obama-care-bears-walruses.json");
const _temp285 = require("./occupation-tactics-come-to-city-hall.json");
const _temp286 = require("./occupy-the-tea-party-not-two-sides-of-the-same-coin.json");
const _temp287 = require("./occupying-hope.json");
const _temp288 = require("./ode-2-2012.json");
const _temp289 = require("./on-again-off-again-wrong-again-corporate-public-policy.json");
const _temp290 = require("./on-the-bones-of-children.json");
const _temp291 = require("./one-inauguration-four-nominations-and-a-funeral-in-just-a-little-bit-presid.json");
const _temp292 = require("./one-of-these-clowns-is-going-to-be-governor.json");
const _temp293 = require("./only-the-happy-people-can-look-at-the-bean.json");
const _temp294 = require("./organized-money-different-parties-same-guys.json");
const _temp295 = require("./organized-money-fights-with-itself-in-public-and-the-people-seek-solidarity.json");
const _temp296 = require("./orwells-mouthpiece.json");
const _temp297 = require("./our-mayor-has-been-busy.json");
const _temp298 = require("./our-money-for-nothing-and-the-city-for-free.json");
const _temp299 = require("./our-policing-problem-police-brutality-reform-isnt-on-the-list.json");
const _temp300 = require("./our-political-system-is-breaking-bad.json");
const _temp301 = require("./our-sick-and-irrational-politics.json");
const _temp302 = require("./owning-up-to-evil.json");
const _temp303 = require("./oxymoronic-private-solutions-to-public-policing-policy.json");
const _temp304 = require("./oxymoronic-realities.json");
const _temp305 = require("./parcc-debacle-a-private-market-rollout-snafu-flattens-public-education.json");
const _temp306 = require("./pension-panic-when-is-a-solution-not-a-solution.json");
const _temp307 = require("./pensions-and-the-idiot-media.json");
const _temp308 = require("./pensions-and-the-idiot-media1.json");
const _temp309 = require("./pensions-for-citizens.json");
const _temp310 = require("./pensions-legal-contracts-social-compacts-and-constitutions.json");
const _temp311 = require("./permanent-wage-slavery.json");
const _temp312 = require("./permission-granted.json");
const _temp313 = require("./permission-is-granted.json");
const _temp314 = require("./permission-is-granted1.json");
const _temp315 = require("./petcoke-and-marmosets-a-neoliberal-in-hell.json");
const _temp316 = require("./phil-ponce-falls-victim-to-common-wisdom-derangement-syndrome.json");
const _temp317 = require("./pity-the-poor-rich.json");
const _temp318 = require("./pizza-trees-and-fruit-pharms.json");
const _temp319 = require("./play-ball-2013.json");
const _temp320 = require("./plenty-of-g-20.json");
const _temp321 = require("./plutocrats-public-policy.json");
const _temp322 = require("./police-effectiveness.json");
const _temp323 = require("./police-reciprocity-and-community-justice.json");
const _temp324 = require("./policing-policy-watch-what-to-look-for.json");
const _temp325 = require("./political-theater-as-job-creation.json");
const _temp326 = require("./political-theater-down-at-city-hall.json");
const _temp327 = require("./politics-be-better-than-lesser-evilism.json");
const _temp328 = require("./politics-the-godzilla-protocol-resisting-the-great-white-shark-of-the-trump.json");
const _temp329 = require("./politics-values-behind-the-curtain.json");
const _temp330 = require("./power-and-the-disposable-class-rahm-emanuel-and-his-union-supporters.json");
const _temp331 = require("./praying-for-godzilla-the-proper-approach-to-the-rauner-madigan-conflict.json");
const _temp332 = require("./precisely-pivoting-polls.json");
const _temp333 = require("./president-donald-trump-will-be-re-elected-yeats-is-still-right.json");
const _temp334 = require("./prisoner-plan-is-polite-slave-labor.json");
const _temp335 = require("./privatization-and-arbitration.json");
const _temp336 = require("./privatization-hold-ups-begin.json");
const _temp337 = require("./progressive-hero-rudy-lozano-sr.json");
const _temp338 = require("./prom-queen-anguish.json");
const _temp339 = require("./promises.-promises-part-1-public-policy-101.json");
const _temp340 = require("./promises.-promises-part-2-how-safer-streets-became-chiraq.json");
const _temp341 = require("./promises.-promises-part-3-its-not-about-the-children.json");
const _temp342 = require("./promises.-promises-part-4-what-if-the-roof-is-on-fire.json");
const _temp343 = require("./public-policy-a-moral-social-contract.json");
const _temp344 = require("./public-policy-is-for-chumps.json");
const _temp345 = require("./public-policy-theater-in-emanueland-the-alchemy-of-stupid.json");
const _temp346 = require("./public-relations-is-not-education-reform.json");
const _temp347 = require("./public-service-epic-fail.json");
const _temp348 = require("./puppets-and-messages.json");
const _temp349 = require("./quality-policing-transformation-vs.-status-quo-police-reformation.json");
const _temp350 = require("./rahm-emanuel-agent-of-austerity.json");
const _temp351 = require("./rahm-emanuel-passes-on-500-million-in-savings.json");
const _temp352 = require("./rahm-in-springfield-land.json");
const _temp353 = require("./rahm-opaque-unaccountable-and-beyond-the-law.json");
const _temp354 = require("./rahm-talks-to-the-people-and-the-people-are-not-amused.json");
const _temp355 = require("./rahms-evil-never-sleeps.json");
const _temp356 = require("./rahms-hedge-funders-create-education-market.json");
const _temp357 = require("./rahms-neo-liberal-ponzi-scheme.json");
const _temp358 = require("./rahms-temporary-policing-strategy.json");
const _temp359 = require("./reality-checking.json");
const _temp360 = require("./rearranging-burning-chairs-on-the-cheap-911-still-a-joke-in-chi-town.json");
const _temp361 = require("./reasons-to-vote-for-either-of-them.json");
const _temp362 = require("./rebranding-evil-the-gop-doesnt-have-a-problem-democracy-does.json");
const _temp363 = require("./recalling-king.json");
const _temp364 = require("./red-meat-slam-dance.json");
const _temp365 = require("./reforming-justice-in-chicago.json");
const _temp366 = require("./remember-how-the-ctu-got-here-a-short-history-of-the-long-fight.json");
const _temp367 = require("./requiem-for-a-torturer.json");
const _temp368 = require("./revolution-counter-revolution-the-political-economy-of-stupid-part-2.json");
const _temp369 = require("./revolution-counter-revolution-the-political-economy-of-stupidity-part-1.json");
const _temp370 = require("./richard-m.-daley-is-cook-countys-defense-budget-problem.json");
const _temp371 = require("./romney-inc.json");
const _temp372 = require("./ron-chew-peaceful-warrior-leaves-us-a-path.json");
const _temp373 = require("./ru-roh-a-dangerously-informed-rundown-on-the-runoff.json");
const _temp374 = require("./run-newt-run.json");
const _temp375 = require("./sacrificing-the-common-good.json");
const _temp376 = require("./safe-passage-to-a-dead-end.json");
const _temp377 = require("./sb1342-public-safety-theater.json");
const _temp378 = require("./school-daze.json");
const _temp379 = require("./sequestration-stage-managed-distraction-real-destruction-and-imaginary-cris.json");
const _temp380 = require("./sequestration-stage-managed-distraction-real-destruction-and-imaginary.json");
const _temp381 = require("./shared-sacrifice-begins-with-a-20-million-dollar-corporate-gift.json");
const _temp382 = require("./shared-sacrifice-begins.json");
const _temp383 = require("./shared-sacrifice-is-not-for-everyone.json");
const _temp384 = require("./shifting-the-tax-burden.json");
const _temp385 = require("./silent-joe-ferguson-shines-light-on-the-new-machine.json");
const _temp386 = require("./simpson-bowles-super-cmte-austerity.json");
const _temp387 = require("./sleeping-through-democracy.json");
const _temp388 = require("./slumlords-and-bankers.json");
const _temp389 = require("./smoke-and-mirrors.json");
const _temp390 = require("./something-wicked-this-way-comes-part-1.json");
const _temp391 = require("./something-wicked-this-way-comes-part-2.json");
const _temp392 = require("./southern-fried-vultures.json");
const _temp393 = require("./spinning-the-media-harming-the-public-and-hiding-from-the-people.json");
const _temp394 = require("./spinning-the-zero-bounce.json");
const _temp395 = require("./spiritual-warfare-on-american-society.json");
const _temp396 = require("./stay-angry-wisconsin-the-battle-never-ends.json");
const _temp397 = require("./stephen-di-benedetto-explains-how-mayor-emanuels-use-of-an-unethical-loopho.json");
const _temp398 = require("./stopping-the-marmoset-machine.json");
const _temp399 = require("./strong-moon.json");
const _temp400 = require("./summer-day-one-2012.json");
const _temp401 = require("./super-duper-congress.json");
const _temp402 = require("./sweet-home-chicago-coalition-steal-home-on-daley-administration.json");
const _temp403 = require("./taxes-and-schools.json");
const _temp404 = require("./teachers-are-the-key.json");
const _temp405 = require("./teachers-for-social-justice.json");
const _temp406 = require("./thanksgiving-blessings-2012.json");
const _temp407 = require("./thats-education-the-candidates-on-citys-future.json");
const _temp408 = require("./the-1-never-rests.json");
const _temp409 = require("./the-2012-political-animal-awards.json");
const _temp410 = require("./the-abyss-looks-back-syria.json");
const _temp411 = require("./the-aca-ruling-a-victory-barely-worth-fighting-for.json");
const _temp412 = require("./the-adventure-thus-far.json");
const _temp413 = require("./the-audacity-of-mendacity.json");
const _temp414 = require("./the-ayatollah-of-pennsylvania.json");
const _temp415 = require("./the-bain-of-his-electoral-existence.json");
const _temp416 = require("./the-barack-obama-election-year-decathlon.json");
const _temp417 = require("./the-batty-battalion.json");
const _temp418 = require("./the-broken-social-contract.json");
const _temp419 = require("./the-care-less-party.json");
const _temp420 = require("./the-care-less-party1.json");
const _temp421 = require("./the-charter-school-brier-patch.json");
const _temp422 = require("./the-chicago-educational-facilities-task-force-creates-cps-accountability.json");
const _temp423 = require("./the-chicago-foreclosure-crisis-only-the-alderman-can-save-us-now.json");
const _temp424 = require("./the-chicago-parents-for-quality-education-takes-to-the-mic-for-common-sense.json");
const _temp425 = require("./the-civic-federation-is-still-not-fing-civic.json");
const _temp426 = require("./the-cme-groups-tax-racket.json");
const _temp427 = require("./the-commodification-of-the-public-good.json");
const _temp428 = require("./the-computer-science-core-curriculum-dance.json");
const _temp429 = require("./the-consequences-of-education-as-a-business.json");
const _temp430 = require("./the-conservative-movement-budget-keeps-trumps-promises-to-both-halves-of-th.json");
const _temp431 = require("./the-ctu-authoritarian-myth-acclamation-authoritarianism-two-different-words.json");
const _temp432 = require("./the-cycle-of-violence-and-homan-square-the-criminal-neglect-of-the-crime-is.json");
const _temp433 = require("./the-day-after-the-first-day-is-always-easiest-when-you-lost.json");
const _temp434 = require("./the-deadly-political-dynamics-of-the-democratic-primary-part-1.json");
const _temp435 = require("./the-deadly-political-dynamics-of-the-democratic-primary-part-2.json");
const _temp436 = require("./the-death-of-trust.json");
const _temp437 = require("./the-delta-of-denial.json");
const _temp438 = require("./the-demise-of-obamacare-is-the-predictable-end-of-non-public-policy.json");
const _temp439 = require("./the-devil-detail-of-the-ward-re-map.json");
const _temp440 = require("./the-devils-advocate.json");
const _temp441 = require("./the-disintegration-of-the-fourth-estate.json");
const _temp442 = require("./the-disposable-politics-of-the-predator-state.json");
const _temp443 = require("./the-dissent-of-democracy.json");
const _temp444 = require("./the-economy-as-musical-chairs.json");
const _temp445 = require("./the-emanuel-administration-circles-wagons-but-not-drain.json");
const _temp446 = require("./the-emanuel-hustle.json");
const _temp447 = require("./the-emperor-has-no-soul.json");
const _temp448 = require("./the-endless-failure-of-corporate-government.json");
const _temp449 = require("./the-evidence-is-obvious-corporate-school-reform-has-already-failed.json");
const _temp450 = require("./the-financial-sector-bares-its-claws.json");
const _temp451 = require("./the-first-defense-last-words-in-the-vote-blue-bernie-or-bust-set-to.json");
const _temp452 = require("./the-forces-of-evil-marshall-the-good.json");
const _temp453 = require("./the-fundamental-education-conversation-part-1.json");
const _temp454 = require("./the-fundamental-education-conversation-part-2.json");
const _temp455 = require("./the-genocidal-dictator-the-gop-congressman-and-the-lobbyist.json");
const _temp456 = require("./the-genocidal-dictator-the-gop-congressman-and-the-lobbyist1.json");
const _temp457 = require("./the-gop-soap-opera.json");
const _temp458 = require("./the-grassroots-collaborative-starts-a-tiff-on-tifs.json");
const _temp459 = require("./the-great-cme-hold-up.json");
const _temp460 = require("./the-great-concessionaire.json");
const _temp461 = require("./the-great-municipal-fund-heist-part-1.json");
const _temp462 = require("./the-great-municipal-fund-heist-part-2.json");
const _temp463 = require("./the-great-municipal-heist-part-3.json");
const _temp464 = require("./the-heart-of-education-is-cooperation-not-competition-stupid-part-ii.json");
const _temp465 = require("./the-heart-of-education-is-cooperation-not-competition...-stupid.json");
const _temp466 = require("./the-illinois-budget-standoff-a-terrorist-kills-hostages-rescue-teams-okay-w.json");
const _temp467 = require("./the-illinois-budget-standoff-terrorist-is-killing-hostages-the-rescue-teams.json");
const _temp468 = require("./the-illinois-budget-standoff-the-terrorist-is-killing-the-hostages-and-the.json");
const _temp469 = require("./the-illogical-syllogism-of-school-closings.json");
const _temp470 = require("./the-imaginary-debate.json");
const _temp471 = require("./the-impunity-of-plutocracy.json");
const _temp472 = require("./the-incredible-fungible-chicago-city-budget.json");
const _temp473 = require("./the-infrastructure-bank-public-private-larceny.json");
const _temp474 = require("./the-janus-effect.json");
const _temp475 = require("./the-law-the-dawn-of-the-new-machine.json");
const _temp476 = require("./the-loan-sharks-have-landed.json");
const _temp477 = require("./the-lost-light-in-a-dark-city-chris-drew.json");
const _temp478 = require("./the-market-based-education-system-it-runs-on-children-and-spits-out-money.json");
const _temp479 = require("./the-meaning-of-zero-in-chicago.json");
const _temp480 = require("./the-moneylenders-invade-the-public-temple.json");
const _temp481 = require("./the-necessary-means-to-be-black-fight-fascism.json");
const _temp482 = require("./the-neo-liberal-shuffle-rahm-is-now-running-against-his-own-platform.json");
const _temp483 = require("./the-neo-liberal-walls-neo-fascist-shadow-the-market-drowns-society-in-a-bat.json");
const _temp484 = require("./the-new-development-is-that-there-no-jobs-developing.json");
const _temp485 = require("./the-new-machine-performance-matters.json");
const _temp486 = require("./the-new-york-primary-its-not-likely-to-change-anything.json");
const _temp487 = require("./the-next-9-11.json");
const _temp488 = require("./the-other-half-of-a-conspiracy.json");
const _temp489 = require("./the-parcc-is-a-public-private-partnership-what-if-the-market-isnt-failing-i.json");
const _temp490 = require("./the-phantom-menacing-the-ctu-strike-rahms-faux-negotiations.json");
const _temp491 = require("./the-plight-of-an-american-moderate.json");
const _temp492 = require("./the-police-reform-monkey-dance-goes-freestyle.json");
const _temp493 = require("./the-political-theater-of-nickels-and-dimes.json");
const _temp494 = require("./the-potemkin-candidate.json");
const _temp495 = require("./the-present-mayoral-race-part-1-the-crack-chicago-stenography-corps-is-not.json");
const _temp496 = require("./the-present-mayoral-race-part-2-the-good-the-bad-and-the-ugly-of-the-candid.json");
const _temp497 = require("./the-president-doesnt-want-to-be-re-elected.json");
const _temp498 = require("./the-presumptive-tax-dodger.json");
const _temp499 = require("./the-public-policy-of-plutocrats-predators-the-charity-of-monsters.json");
const _temp500 = require("./the-quality-school-day.json");
const _temp501 = require("./the-rahmbo-narrative-vs.-the-emanuel-reality.json");
const _temp502 = require("./the-responsible-budget-ordinance.json");
const _temp503 = require("./the-road-to-five-hundred-plus.json");
const _temp504 = require("./the-same-side-of-an-ugly-coin.json");
const _temp505 = require("./the-shuck-and-jive-of-thirty-pieces-of-silver-returns.json");
const _temp506 = require("./the-state-of-our-city.json");
const _temp507 = require("./the-stealth-convention.json");
const _temp508 = require("./the-stupidity-of-cupidity.json");
const _temp509 = require("./the-sun-times-and-tribune-killing-democracy-one-column-inch-at-a-time.json");
const _temp510 = require("./the-tea-party-made-clear.json");
const _temp511 = require("./the-teachers-strike-reminds-us-which-side-rahms-always-been-on.json");
const _temp512 = require("./the-ten-females-who-cost-mitt-romney-the-presidency.json");
const _temp513 = require("./the-third-poll.json");
const _temp514 = require("./the-top-ten-comedic-news-stories-of-2011.json");
const _temp515 = require("./the-trauma-of-socioeconomic-violence.json");
const _temp516 = require("./the-tutorial-picks-the-primaries.json");
const _temp517 = require("./the-victory-speech-should-worry-you.json");
const _temp518 = require("./the-whittier-lessons.json");
const _temp519 = require("./the-wrong-stuff.json");
const _temp520 = require("./the-wrongest-side-of-history.json");
const _temp521 = require("./there-is-no-business-model-for-public-safety.json");
const _temp522 = require("./there-is-nothing-civic-about-the-civic-federation-part-50.json");
const _temp523 = require("./there-is-nothing-non-partisan-about-the-civic-federation.json");
const _temp524 = require("./theres-no-conflict-after-a-hostile-takeover.json");
const _temp525 = require("./theres-nothing-funny-about-these-candidates.json");
const _temp526 = require("./thinking-like-a-business-is-bad-public-policy.json");
const _temp527 = require("./thirteen-ways-of-looking-at-the-occupation-apologies-to-wallace-stevens.json");
const _temp528 = require("./three-words-do-not-an-issue-make-kicking-page-may-as-cover-for-beating-on-c.json");
const _temp529 = require("./tina-fails-again-fiscal-policy-in-emanuel-land.json");
const _temp530 = require("./to-fight-them.json");
const _temp531 = require("./to-fight-them1.json");
const _temp532 = require("./today-we-organize.json");
const _temp533 = require("./tone-deaf-tin-eared-borg.json");
const _temp534 = require("./tone-deaf-tin-eared-borg1.json");
const _temp535 = require("./toni-vs.-rahm-a-tale-of-wolves-xenomorphs.json");
const _temp536 = require("./tough-guys-dance-but-dont-run.json");
const _temp537 = require("./transformation-not-reformation-we-need-more-than-a-mayor.json");
const _temp538 = require("./transparency.json");
const _temp539 = require("./tribunes-national-news-leaves-citizen-voters-helpless-and-uninformed.json");
const _temp540 = require("./trickle-up-economics.json");
const _temp541 = require("./trust-me-really.json");
const _temp542 = require("./turkey-holocaust-day-2011.json");
const _temp543 = require("./twilight-of-integrity.json");
const _temp544 = require("./understanding-money.json");
const _temp545 = require("./understanding-the-un-accountability-monkey-dance-on-copa.json");
const _temp546 = require("./vandals-at-the-public-gates-and-inside-the-citys-walls.json");
const _temp547 = require("./ventra-a-micro-example-of-macro-loan-sharking.json");
const _temp548 = require("./veterans-day.json");
const _temp549 = require("./vetting-for-the-wrong-thing.json");
const _temp550 = require("./voiceless.json");
const _temp551 = require("./wal-mart-advocate-runs-african-american-caucus.json");
const _temp552 = require("./war-on-the-future-chicago-public-schools-cps-tries-to-free-itself-of-public.json");
const _temp553 = require("./watch-the-hungry-fight-for-crumbs-in-the-rubble.json");
const _temp554 = require("./watching-the-watchers.json");
const _temp555 = require("./we-are-how-we-behave.json");
const _temp556 = require("./we-are-the-hungry-in-rubble.json");
const _temp557 = require("./we-dont-need-no-education-same-as-it-ever-was-worse-than-you-think.json");
const _temp558 = require("./we-must-rally.json");
const _temp559 = require("./we-need-police-transformation-not-mayor-emanuels-head-but-that-would-be-nic.json");
const _temp560 = require("./we-tire-of-the-constitution-time-to-leave-the-18th-century.json");
const _temp561 = require("./wearing-my-debate-fatigues.json");
const _temp562 = require("./weasel-boy-versus-plastic-man.json");
const _temp563 = require("./weinergate.json");
const _temp564 = require("./were-all-muppets-here.json");
const _temp565 = require("./whack-a-pol.json");
const _temp566 = require("./what-cha-tells-us-about-public-policy.json");
const _temp567 = require("./what-is-this-g8-thing-people-are-talking-about-and-why-should-i-care.json");
const _temp568 = require("./what-needs-watching.json");
const _temp569 = require("./what-questions-reveal.json");
const _temp570 = require("./what-we-learned-today-bad-reporting-just-gets-worse.json");
const _temp571 = require("./what-went-wrong-out-there-or-how-an-angry-marmoset-missed-a-branch.json");
const _temp572 = require("./when-a-ceo-mayor-cant-do-math.json");
const _temp573 = require("./when-no-one-knows-nothing-polling-ninja-dust-the-bottomless-stupidity-of-th.json");
const _temp574 = require("./when-numbers-mean-nothing.json");
const _temp575 = require("./when-will-the-question-measure-up-to-the-subject.json");
const _temp576 = require("./while-we-react-to-physical-police-violence-rahms-financial-partners-do-fisc.json");
const _temp577 = require("./whittier-parents-and-activists.json");
const _temp578 = require("./who-wins-why.json");
const _temp579 = require("./why-there-is-no-left-wing-tea-party.json");
const _temp580 = require("./why-theres-no-reform-in-the-mayors-budget.json");
const _temp581 = require("./will-dursts-2013-political-animal-awards.json");
const _temp582 = require("./will-durt-2011-xma-gift-wih-lit.json");
const _temp583 = require("./will-no-one-rid-me-of-this-turbulent-priest.json");
const _temp584 = require("./will-the-city-that-works-start-working-for-african-americans.json");
const _temp585 = require("./worst-campaign-ever.json");
const _temp586 = require("./yes-virginia-some-men-are-pigs.json");
module.exports = {
  "13-new-aldermen-1-new-mayor-reform": _temp0,
  "2012-veepstakes": _temp1,
  "2013-shoulda-coulda-woulda-resolutions": _temp2,
  "9-11-is-a-joke": _temp3,
  "a-13-million-dollar-corporate-hold-up": _temp4,
  "a-bankster-reunion": _temp5,
  "a-beginners-guide-to-the-fiscal-cliff-part-1": _temp6,
  "a-beginners-guide-to-the-fiscal-cliff": _temp7,
  "a-blueprint-for-serfdom": _temp8,
  "a-brief-history-a-small-mammal-with-a-big-dream": _temp9,
  "a-broken-promise-on-education-part-i-of-ii": _temp10,
  "a-broken-promise-on-education-part-ii-of-ii": _temp11,
  "a-broken-system-in-interesting-times": _temp12,
  "a-chance-to-walk-to-the-talk": _temp13,
  "a-comparative-story": _temp14,
  "a-conspiracy-of-common-interests": _temp15,
  "a-convergence-of-interests": _temp16,
  "a-cynical-rahm-finds-jesus-and-earns-odins-wrath-on-the-road-to-re-election": _temp17,
  "a-devils-standard": _temp18,
  "a-fair-deal": _temp19,
  "a-fight-to-watch": _temp20,
  "a-final-nato-media-rant": _temp21,
  "a-glimpse-into-our-mayors-soul": _temp22,
  "a-guy-that-cant-talk-lectures": _temp23,
  "a-housing-victory-for-restorative-justice": _temp24,
  "a-jobless-recovery-is-not-a-recovery": _temp25,
  "a-little-thing-on-tifs": _temp26,
  "a-map-without-values": _temp27,
  "a-movement-speech": _temp28,
  "a-nation-in-trouble": _temp29,
  "a-neoliberal-education": _temp30,
  "a-picture-paints-10000-words": _temp31,
  "a-political-reality-check": _temp32,
  "a-public-policy-primer-on-the-emanuel-administration": _temp33,
  "a-retrospective-on-mayor-emanuel": _temp34,
  "a-sanity-based-venn-diagram-why-bernie-or-bust-wont-move-the-democrats-or-t": _temp35,
  "a-scoop-of-non-vanilla": _temp36,
  "a-simple-truth": _temp37,
  "a-striking-context-rahm-vs.-the-ctu": _temp38,
  "a-tale-of-two-romneys": _temp39,
  "a-thin-blue-mirage": _temp40,
  "a-time-before-the-barricades": _temp41,
  "a-time-of-scapegoats-or-solutions": _temp42,
  "a-trajectory-of-cruelty": _temp43,
  "a-view-from-washington-a-convergence-of-interests": _temp44,
  "a-view-from-washington-a-humane-society": _temp45,
  "a-view-from-washington-the-common-public-good-or-ucommon-private-violence-a": _temp46,
  "a-weak-democracy-yields-a-weaker-debate": _temp47,
  "abdon-pallasch-loses-the-context-key": _temp48,
  "absurdity-in-public-policy": _temp49,
  "adventures-in-privatized-contracting": _temp50,
  "after-two-years-ig-ferguson-discovers-the-m-wbe-program-is-corrupt": _temp51,
  "agenda-narrative-the-grassroots-collaboratives-challenge": _temp52,
  "agents-of-darkness": _temp53,
  "ald-munoz-attempt-to-restore-democracy-to-chicago": _temp54,
  "ald.-bob-and-wee-willie-wilson-a-study-in-neo-liberal-supremacy": _temp55,
  "aldermanic-prerogatives-in-peril": _temp56,
  "alec-rahm-a-public-private-partnership": _temp57,
  "alejandro-escalona-needs-to-stop-giving-journalism-lectures-and-do-some-jou": _temp58,
  "all-the-canaries-are-dying": _temp59,
  "american-masada-where-aggrievement-meets-magical-thinking-and-aggrievement": _temp60,
  "an-alderman-with-values": _temp61,
  "an-evil-zero-sum-gain": _temp62,
  "an-existential-threat-to-democracy": _temp63,
  "an-honest-discussion-of-the-public-good": _temp64,
  "an-idiots-debate": _temp65,
  "anatomy-of-a-crime": _temp66,
  "angrier-birds": _temp67,
  "angry-at-the-wrong-people": _temp68,
  "another-day-we-need-a-dollar-the-candidates-on-job-creation": _temp69,
  "another-evening-of-presidential-disappointment": _temp70,
  "another-mechanism-to-loot-the-public": _temp71,
  "arent-city-workers-taxpayers": _temp72,
  "around-emanuel-land-in-101-days": _temp73,
  "austerity-follies-rahms-cta": _temp74,
  "austerity-in-a-land-of-plenty": _temp75,
  "back-in-the-fold": _temp76,
  "bad-foundation-mojo": _temp77,
  "bad-structure-austeritys-reach": _temp78,
  "banana-government-theater": _temp79,
  "barack-h.-obama-2012-state-of-the-union-drinking-game": _temp80,
  "be-a-citizen-no-ones-coming-to-save-us": _temp81,
  "benghazi-smoke-screen": _temp82,
  "big-business-now-owns-the-city-what-to-look-for": _temp83,
  "blackest-day-darkest-night-the-death-of-the-public-trust": _temp84,
  "budget-rant": _temp85,
  "budgeting-in-the-dark": _temp86,
  "budgets-are-moral-documents": _temp87,
  "building-the-infrastructure-for-privatization": _temp88,
  "calamitys-equation": _temp89,
  "campaign-treasure-hunters": _temp90,
  "can-a-possibly-criminal-incompetent-agency-be-half-right": _temp91,
  "candidates": _temp92,
  "casino-madness": _temp93,
  "casinos-bankers-bad-crony-public-policy": _temp94,
  "cassand": _temp95,
  "cassandra-on-the-first-debate-coverage": _temp96,
  "categories": _temp97,
  "ceo-mittens-doesnt-know-how-the-economy-works": _temp98,
  "champions-of-the-city-save-our-schools": _temp99,
  "champions-of-the-city-ue-local-1110-stands-up-for-itself": _temp100,
  "champions-of-the-city-uic-professors-become-unruly": _temp101,
  "charm-offensive": _temp102,
  "charter-schools-devour-the-common-public-good-the-agenda-is-still-running": _temp103,
  "chicago-is-still-aint-ready-for-reform-the-chicago-police-department-slips": _temp104,
  "chicago-policing-strategy-same-as-it-ever-was-worse-than-you-think": _temp105,
  "chicagos-budget-what-the-evil-devised-and-the-stupid-voted-for": _temp106,
  "chicagos-i-trust-public-policy-epic-fail": _temp107,
  "chicagos-infrastructure-bank-when-foxes-franchise-the-building-guarding-of": _temp108,
  "citizen-bad-ass-mick-dumke": _temp109,
  "civic-imagination-what-makes-a-solution-to-a-real-problem-practical-or-poss": _temp110,
  "civic-vampires-the-rentier-class": _temp111,
  "civic-washing-satans-laundry": _temp112,
  "class-warfare-as-economic-development-in-rahms-chicago": _temp113,
  "climate-destruction-by-the-numbers": _temp114,
  "cme-must-pay-to-go": _temp115,
  "comcasting-a-spell-over-the-city": _temp116,
  "commissioner-chuy-garcia-fights-for-the-constitution": _temp117,
  "conserving-democracy": _temp118,
  "conserving-democracy1": _temp119,
  "context-to-rahms-credit-card-crackdown": _temp120,
  "cornered-teachers": _temp121,
  "corroded-clockwork": _temp122,
  "creating-discipline-the-old-fashioned-way": _temp123,
  "credit-card-fraud-and-the-emanuel-administration": _temp124,
  "criminal-austerity-gets-an-assist": _temp125,
  "crisis-hypothesis-the-undead-hand-of-milton-friedman-kills-government...-ag": _temp126,
  "crouching-lurkers": _temp127,
  "cry-for-the-children": _temp128,
  "da-negotiator-what-next": _temp129,
  "daleys-legacy-is-corruption": _temp130,
  "dangerous-and-unhealthy-work-conditions-or-625-salary-bombs-await-unions": _temp131,
  "dark-wizards-convene": _temp132,
  "deceptions-hammer": _temp133,
  "decoupled": _temp134,
  "deja-vu-all-over-again": _temp135,
  "deju-vu-all-over-again": _temp136,
  "democracy-and-austerity-cannot-exist": _temp137,
  "democracy-is-the-battle-that-never-ends": _temp138,
  "democracys-messenger": _temp139,
  "demons-among-us": _temp140,
  "designated-scapegoat-cha-ceo-lewis-jordan-gets-sacrificed": _temp141,
  "dirty-schools-and-filthy-food-at-a-union-busting-profit": _temp142,
  "disordering-democracy-a-g8-nato-strategy": _temp143,
  "dispatches-from-the-front-lines": _temp144,
  "divide-conqueror-and-blame": _temp145,
  "divide.-conquer.-rinse.-repeat.-mayor-emanuel-at-work": _temp146,
  "donald-trump-stop-defaming-frankensteins-monster": _temp147,
  "dont-know-much-about-history-public-education-in-privatized-emanueland-part": _temp148,
  "dont-know-much-about-history-public-education-in-privatized-emanueland": _temp149,
  "dont-know-much-about-history-public-education-in-privatized-emanueland1": _temp150,
  "dont-know-much-about-history-public-education-in-privatized-emanueland2": _temp151,
  "eating-education-for-lunch": _temp152,
  "education-experts-attempt-the-educate-the-illiterate": _temp153,
  "education-public-policy-is-in-a-school-daze": _temp154,
  "election-day-and-uncertaintys-challenge": _temp155,
  "election-over-a-dangerously-informed-take-on-reality-part-1-of-3": _temp156,
  "election-over-a-dangerously-informed-take-on-reality-part-2-of-3": _temp157,
  "election-over-a-dangerously-informed-take-on-reality-part-3-of-3": _temp158,
  "elections-matter-control-the-state-or-be-consumed-by-it": _temp159,
  "emanuel-attacks-teachers...-again": _temp160,
  "emanuel-vs.-transparency-ethics": _temp161,
  "emanuels-30-day-grade-depends-on-the-questions-not-the-answers": _temp162,
  "equal-is-as-equal-does": _temp163,
  "ethical-irony": _temp164,
  "ethics-and-all": _temp165,
  "everyday-armageddon": _temp166,
  "evil-in-a-bottle-a-tutorial-on-syria-part-i-of-ii": _temp167,
  "evil-sneaky-petty-and-stupid-power-in-emanuel-land-2": _temp168,
  "evil-sneaky-petty-and-stupid-power-in-emanuel-land": _temp169,
  "failing-upwards-remember-who-gery-chico-is": _temp170,
  "fight-joe-moreno-who-knew": _temp171,
  "fight-like-a-genius": _temp172,
  "fight-them-vote": _temp173,
  "fighting-for-something-or-losing-everything": _temp174,
  "fighting-over-scraps-in-the-rubble": _temp175,
  "figure-head-burke-sock-puppet-oconnor-on-the-4th-amendment": _temp176,
  "fixing-schools-but-good": _temp177,
  "flash-in-pan-panic": _temp178,
  "following-finney": _temp179,
  "for-a-better-chicago-other-emanuel-allies-need-an-education-on-education": _temp180,
  "for-a-blacker-more-realistic-america": _temp181,
  "fortune-telling-on-inauguration-day": _temp182,
  "four-reasons-nato-is-a-no-no": _temp183,
  "fran-speilman-political-comedy-writer": _temp184,
  "fran-spielman-turns-a-6-year-old-into-a-political-puppet": _temp185,
  "fran-spielman-turns-stenographer": _temp186,
  "from-patronage-to-pinstripes-to-star-chamber-politic": _temp187,
  "gambling-you-can-steal-more-with-a-briefcase": _temp188,
  "gangster-capitalism-the-civic-federation-provides-mayor-emanuel-cover": _temp189,
  "gauntlets-thrown-down-gauges-dropped-gloves-off-and-belled-cats-its-on": _temp190,
  "gibberish-manure": _temp191,
  "gifts-for-corporations-pain-for-social-service-providers": _temp192,
  "give-us-some-issue-part-1": _temp193,
  "give-us-some-issues-hackfest-part-2": _temp194,
  "goldman-sachs-eats-more-small-businesses": _temp195,
  "good-omens": _temp196,
  "gop-pledge-drive": _temp197,
  "grope-and-change": _temp198,
  "half-empty-and-over-full": _temp199,
  "half-measures-never-saved-anyone": _temp200,
  "hallmarks-of-good-budget-and-taxation-policy-part-1": _temp201,
  "high-costs-of-corporate-policing": _temp202,
  "high-on-the-mainstream-embankment": _temp203,
  "history-is-never-over-stop-looking-for-answers-that-dont-matter": _temp204,
  "home": _temp205,
  "homeland-security-theater-games": _temp206,
  "hot-dog-time": _temp207,
  "how-about-a-little-help": _temp208,
  "how-dumb-we-supposed-to-be": _temp209,
  "i-dont-know-nothing": _temp210,
  "i-hate-the-numbers-9-and-11": _temp211,
  "i-predict-a-riot-the-candidates-on-public-safety": _temp212,
  "i-tire-of-the-20th-century": _temp213,
  "i-want-the-news-back": _temp214,
  "icirr-is-living-the-dream": _temp215,
  "idiot-economic-conversation": _temp216,
  "illinois-gov.-race-2014-no-ones-on-your-side": _temp217,
  "in-the-wake-of-the-wrecking-ball": _temp218,
  "innocence-projects-founder-doesnt-get-a-trial": _temp219,
  "invoking-children-to-help-anti-child-education-agenda": _temp220,
  "irony-idiocy-and-the-irredeemable-reality-of-our-politics": _temp221,
  "it-took-nixon-to-open-china-and-obama-to-close-medicare-medicaid-and-social": _temp222,
  "its-about-the-constitution-stupid": _temp223,
  "its-all-about-the-buildings": _temp224,
  "its-called-a-fact-check-media-people": _temp225,
  "its-called-crony-capitalism": _temp226,
  "its-either-cicero-or-solon-the-choice-is-coming-sooner-not-later": _temp227,
  "its-just-a-heist-you-can-steal-more-with-a-briefcase-than-you-can-with-a-gu": _temp228,
  "its-just-money-the-candidates-on-fiscal-policy": _temp229,
  "its-my-money": _temp230,
  "its-not-about-education-its-about-money": _temp231,
  "its-not-about-education-stupid": _temp232,
  "its-not-style-its-substance-rahm-vs.-chuy-isnt-about-santa-and-lumps-of-coa": _temp233,
  "its-not-the-debt-its-the-plan": _temp234,
  "its-the-administration-not-the-amer": _temp235,
  "jack-asks-when-does-700-3000": _temp236,
  "jack-in-transition": _temp237,
  "job-creation-1-style": _temp238,
  "jobs-is-it": _temp239,
  "jobs-w-justice-holds-first-friday-smackdowns": _temp240,
  "judges-referendums-a-tutorial-guide": _temp241,
  "katrina-ed": _temp242,
  "keeping-count-with-content": _temp243,
  "killer-carnivorous-snails-from-france": _temp244,
  "king-is-here": _temp245,
  "laborers-local-1001-eats-their-young": _temp246,
  "laquan-mcdonald-the-public-execution-of-brutal-public-policies": _temp247,
  "lawyers-guns-and-money-the-economics-of-public-safety": _temp248,
  "let-the-better-policing-begin-one-promise-down": _temp249,
  "lets-make-a-deal": _temp250,
  "light-will-prevail": _temp251,
  "long-live-the-king": _temp252,
  "looting-the-public-trust-rahm-opens-the-education-market-wide": _temp253,
  "losing-chicagos-commons": _temp254,
  "managed-competition-is-the-actual-garbage": _temp255,
  "marmoset-supremacy-part-2-balancing-broken-budgets-schools": _temp256,
  "marmoset-supremacy-part-3-education-advocacy": _temp257,
  "marmoset-supremacy-part-4-shut-up-for-your-own-safety-sir": _temp258,
  "marmoset-supremacy-rahms-record-part-1": _temp259,
  "maternity-leave": _temp260,
  "mayor-emanuel-demands-citizens-pay-a-19.6-million-dollar-corporate-fee": _temp261,
  "mayor-emanuel-knows-who-he-is": _temp262,
  "mayor-emanuel-opens-an-open-book": _temp263,
  "mayor-emanuel-sacrifices-worker-safety-and-cuts-20-million-in-city-services": _temp264,
  "mayor-emanuel-the-case-for-four-more-years": _temp265,
  "mayor-emanuel-the-city-council-pass-a-police-accountability-law-without-any": _temp266,
  "mayor-emanuels-big-blue-con-job-nothing-new-to-see-here-kids-expect-more-vi": _temp267,
  "mayor-emanuels-police-promise": _temp268,
  "mayor-rahm-emanuel-the-hapless-cabin-boy-to-the-captains-of-cutthroat-indus": _temp269,
  "meanwhile-back-in-chicago": _temp270,
  "michael-mcconnnell-a-peaceful-force-for-justice": _temp271,
  "misdirection-and-privatization": _temp272,
  "mister-muzzle-nuzzle": _temp273,
  "monumental-trouble": _temp274,
  "my-mayor-is-not-going-anywhere": _temp275,
  "my-pirate-ship-finally-sets-sail": _temp276,
  "myth-busting-unsustainable-policing": _temp277,
  "myth-making-in-chicago-part-1": _temp278,
  "myth-making-in-chicago-part-2": _temp279,
  "no-one-is-coming-to-save-us-but-us": _temp280,
  "no-ones-on-our-side": _temp281,
  "noreen-ahmed-doesnt-know-enough-about-chicago-communities": _temp282,
  "not-so-super-tuesday-report": _temp283,
  "obama-care-bears-walruses": _temp284,
  "occupation-tactics-come-to-city-hall": _temp285,
  "occupy-the-tea-party-not-two-sides-of-the-same-coin": _temp286,
  "occupying-hope": _temp287,
  "ode-2-2012": _temp288,
  "on-again-off-again-wrong-again-corporate-public-policy": _temp289,
  "on-the-bones-of-children": _temp290,
  "one-inauguration-four-nominations-and-a-funeral-in-just-a-little-bit-presid": _temp291,
  "one-of-these-clowns-is-going-to-be-governor": _temp292,
  "only-the-happy-people-can-look-at-the-bean": _temp293,
  "organized-money-different-parties-same-guys": _temp294,
  "organized-money-fights-with-itself-in-public-and-the-people-seek-solidarity": _temp295,
  "orwells-mouthpiece": _temp296,
  "our-mayor-has-been-busy": _temp297,
  "our-money-for-nothing-and-the-city-for-free": _temp298,
  "our-policing-problem-police-brutality-reform-isnt-on-the-list": _temp299,
  "our-political-system-is-breaking-bad": _temp300,
  "our-sick-and-irrational-politics": _temp301,
  "owning-up-to-evil": _temp302,
  "oxymoronic-private-solutions-to-public-policing-policy": _temp303,
  "oxymoronic-realities": _temp304,
  "parcc-debacle-a-private-market-rollout-snafu-flattens-public-education": _temp305,
  "pension-panic-when-is-a-solution-not-a-solution": _temp306,
  "pensions-and-the-idiot-media": _temp307,
  "pensions-and-the-idiot-media1": _temp308,
  "pensions-for-citizens": _temp309,
  "pensions-legal-contracts-social-compacts-and-constitutions": _temp310,
  "permanent-wage-slavery": _temp311,
  "permission-granted": _temp312,
  "permission-is-granted": _temp313,
  "permission-is-granted1": _temp314,
  "petcoke-and-marmosets-a-neoliberal-in-hell": _temp315,
  "phil-ponce-falls-victim-to-common-wisdom-derangement-syndrome": _temp316,
  "pity-the-poor-rich": _temp317,
  "pizza-trees-and-fruit-pharms": _temp318,
  "play-ball-2013": _temp319,
  "plenty-of-g-20": _temp320,
  "plutocrats-public-policy": _temp321,
  "police-effectiveness": _temp322,
  "police-reciprocity-and-community-justice": _temp323,
  "policing-policy-watch-what-to-look-for": _temp324,
  "political-theater-as-job-creation": _temp325,
  "political-theater-down-at-city-hall": _temp326,
  "politics-be-better-than-lesser-evilism": _temp327,
  "politics-the-godzilla-protocol-resisting-the-great-white-shark-of-the-trump": _temp328,
  "politics-values-behind-the-curtain": _temp329,
  "power-and-the-disposable-class-rahm-emanuel-and-his-union-supporters": _temp330,
  "praying-for-godzilla-the-proper-approach-to-the-rauner-madigan-conflict": _temp331,
  "precisely-pivoting-polls": _temp332,
  "president-donald-trump-will-be-re-elected-yeats-is-still-right": _temp333,
  "prisoner-plan-is-polite-slave-labor": _temp334,
  "privatization-and-arbitration": _temp335,
  "privatization-hold-ups-begin": _temp336,
  "progressive-hero-rudy-lozano-sr": _temp337,
  "prom-queen-anguish": _temp338,
  "promises.-promises-part-1-public-policy-101": _temp339,
  "promises.-promises-part-2-how-safer-streets-became-chiraq": _temp340,
  "promises.-promises-part-3-its-not-about-the-children": _temp341,
  "promises.-promises-part-4-what-if-the-roof-is-on-fire": _temp342,
  "public-policy-a-moral-social-contract": _temp343,
  "public-policy-is-for-chumps": _temp344,
  "public-policy-theater-in-emanueland-the-alchemy-of-stupid": _temp345,
  "public-relations-is-not-education-reform": _temp346,
  "public-service-epic-fail": _temp347,
  "puppets-and-messages": _temp348,
  "quality-policing-transformation-vs.-status-quo-police-reformation": _temp349,
  "rahm-emanuel-agent-of-austerity": _temp350,
  "rahm-emanuel-passes-on-500-million-in-savings": _temp351,
  "rahm-in-springfield-land": _temp352,
  "rahm-opaque-unaccountable-and-beyond-the-law": _temp353,
  "rahm-talks-to-the-people-and-the-people-are-not-amused": _temp354,
  "rahms-evil-never-sleeps": _temp355,
  "rahms-hedge-funders-create-education-market": _temp356,
  "rahms-neo-liberal-ponzi-scheme": _temp357,
  "rahms-temporary-policing-strategy": _temp358,
  "reality-checking": _temp359,
  "rearranging-burning-chairs-on-the-cheap-911-still-a-joke-in-chi-town": _temp360,
  "reasons-to-vote-for-either-of-them": _temp361,
  "rebranding-evil-the-gop-doesnt-have-a-problem-democracy-does": _temp362,
  "recalling-king": _temp363,
  "red-meat-slam-dance": _temp364,
  "reforming-justice-in-chicago": _temp365,
  "remember-how-the-ctu-got-here-a-short-history-of-the-long-fight": _temp366,
  "requiem-for-a-torturer": _temp367,
  "revolution-counter-revolution-the-political-economy-of-stupid-part-2": _temp368,
  "revolution-counter-revolution-the-political-economy-of-stupidity-part-1": _temp369,
  "richard-m.-daley-is-cook-countys-defense-budget-problem": _temp370,
  "romney-inc": _temp371,
  "ron-chew-peaceful-warrior-leaves-us-a-path": _temp372,
  "ru-roh-a-dangerously-informed-rundown-on-the-runoff": _temp373,
  "run-newt-run": _temp374,
  "sacrificing-the-common-good": _temp375,
  "safe-passage-to-a-dead-end": _temp376,
  "sb1342-public-safety-theater": _temp377,
  "school-daze": _temp378,
  "sequestration-stage-managed-distraction-real-destruction-and-imaginary-cris": _temp379,
  "sequestration-stage-managed-distraction-real-destruction-and-imaginary": _temp380,
  "shared-sacrifice-begins-with-a-20-million-dollar-corporate-gift": _temp381,
  "shared-sacrifice-begins": _temp382,
  "shared-sacrifice-is-not-for-everyone": _temp383,
  "shifting-the-tax-burden": _temp384,
  "silent-joe-ferguson-shines-light-on-the-new-machine": _temp385,
  "simpson-bowles-super-cmte-austerity": _temp386,
  "sleeping-through-democracy": _temp387,
  "slumlords-and-bankers": _temp388,
  "smoke-and-mirrors": _temp389,
  "something-wicked-this-way-comes-part-1": _temp390,
  "something-wicked-this-way-comes-part-2": _temp391,
  "southern-fried-vultures": _temp392,
  "spinning-the-media-harming-the-public-and-hiding-from-the-people": _temp393,
  "spinning-the-zero-bounce": _temp394,
  "spiritual-warfare-on-american-society": _temp395,
  "stay-angry-wisconsin-the-battle-never-ends": _temp396,
  "stephen-di-benedetto-explains-how-mayor-emanuels-use-of-an-unethical-loopho": _temp397,
  "stopping-the-marmoset-machine": _temp398,
  "strong-moon": _temp399,
  "summer-day-one-2012": _temp400,
  "super-duper-congress": _temp401,
  "sweet-home-chicago-coalition-steal-home-on-daley-administration": _temp402,
  "taxes-and-schools": _temp403,
  "teachers-are-the-key": _temp404,
  "teachers-for-social-justice": _temp405,
  "thanksgiving-blessings-2012": _temp406,
  "thats-education-the-candidates-on-citys-future": _temp407,
  "the-1-never-rests": _temp408,
  "the-2012-political-animal-awards": _temp409,
  "the-abyss-looks-back-syria": _temp410,
  "the-aca-ruling-a-victory-barely-worth-fighting-for": _temp411,
  "the-adventure-thus-far": _temp412,
  "the-audacity-of-mendacity": _temp413,
  "the-ayatollah-of-pennsylvania": _temp414,
  "the-bain-of-his-electoral-existence": _temp415,
  "the-barack-obama-election-year-decathlon": _temp416,
  "the-batty-battalion": _temp417,
  "the-broken-social-contract": _temp418,
  "the-care-less-party": _temp419,
  "the-care-less-party1": _temp420,
  "the-charter-school-brier-patch": _temp421,
  "the-chicago-educational-facilities-task-force-creates-cps-accountability": _temp422,
  "the-chicago-foreclosure-crisis-only-the-alderman-can-save-us-now": _temp423,
  "the-chicago-parents-for-quality-education-takes-to-the-mic-for-common-sense": _temp424,
  "the-civic-federation-is-still-not-fing-civic": _temp425,
  "the-cme-groups-tax-racket": _temp426,
  "the-commodification-of-the-public-good": _temp427,
  "the-computer-science-core-curriculum-dance": _temp428,
  "the-consequences-of-education-as-a-business": _temp429,
  "the-conservative-movement-budget-keeps-trumps-promises-to-both-halves-of-th": _temp430,
  "the-ctu-authoritarian-myth-acclamation-authoritarianism-two-different-words": _temp431,
  "the-cycle-of-violence-and-homan-square-the-criminal-neglect-of-the-crime-is": _temp432,
  "the-day-after-the-first-day-is-always-easiest-when-you-lost": _temp433,
  "the-deadly-political-dynamics-of-the-democratic-primary-part-1": _temp434,
  "the-deadly-political-dynamics-of-the-democratic-primary-part-2": _temp435,
  "the-death-of-trust": _temp436,
  "the-delta-of-denial": _temp437,
  "the-demise-of-obamacare-is-the-predictable-end-of-non-public-policy": _temp438,
  "the-devil-detail-of-the-ward-re-map": _temp439,
  "the-devils-advocate": _temp440,
  "the-disintegration-of-the-fourth-estate": _temp441,
  "the-disposable-politics-of-the-predator-state": _temp442,
  "the-dissent-of-democracy": _temp443,
  "the-economy-as-musical-chairs": _temp444,
  "the-emanuel-administration-circles-wagons-but-not-drain": _temp445,
  "the-emanuel-hustle": _temp446,
  "the-emperor-has-no-soul": _temp447,
  "the-endless-failure-of-corporate-government": _temp448,
  "the-evidence-is-obvious-corporate-school-reform-has-already-failed": _temp449,
  "the-financial-sector-bares-its-claws": _temp450,
  "the-first-defense-last-words-in-the-vote-blue-bernie-or-bust-set-to": _temp451,
  "the-forces-of-evil-marshall-the-good": _temp452,
  "the-fundamental-education-conversation-part-1": _temp453,
  "the-fundamental-education-conversation-part-2": _temp454,
  "the-genocidal-dictator-the-gop-congressman-and-the-lobbyist": _temp455,
  "the-genocidal-dictator-the-gop-congressman-and-the-lobbyist1": _temp456,
  "the-gop-soap-opera": _temp457,
  "the-grassroots-collaborative-starts-a-tiff-on-tifs": _temp458,
  "the-great-cme-hold-up": _temp459,
  "the-great-concessionaire": _temp460,
  "the-great-municipal-fund-heist-part-1": _temp461,
  "the-great-municipal-fund-heist-part-2": _temp462,
  "the-great-municipal-heist-part-3": _temp463,
  "the-heart-of-education-is-cooperation-not-competition-stupid-part-ii": _temp464,
  "the-heart-of-education-is-cooperation-not-competition...-stupid": _temp465,
  "the-illinois-budget-standoff-a-terrorist-kills-hostages-rescue-teams-okay-w": _temp466,
  "the-illinois-budget-standoff-terrorist-is-killing-hostages-the-rescue-teams": _temp467,
  "the-illinois-budget-standoff-the-terrorist-is-killing-the-hostages-and-the": _temp468,
  "the-illogical-syllogism-of-school-closings": _temp469,
  "the-imaginary-debate": _temp470,
  "the-impunity-of-plutocracy": _temp471,
  "the-incredible-fungible-chicago-city-budget": _temp472,
  "the-infrastructure-bank-public-private-larceny": _temp473,
  "the-janus-effect": _temp474,
  "the-law-the-dawn-of-the-new-machine": _temp475,
  "the-loan-sharks-have-landed": _temp476,
  "the-lost-light-in-a-dark-city-chris-drew": _temp477,
  "the-market-based-education-system-it-runs-on-children-and-spits-out-money": _temp478,
  "the-meaning-of-zero-in-chicago": _temp479,
  "the-moneylenders-invade-the-public-temple": _temp480,
  "the-necessary-means-to-be-black-fight-fascism": _temp481,
  "the-neo-liberal-shuffle-rahm-is-now-running-against-his-own-platform": _temp482,
  "the-neo-liberal-walls-neo-fascist-shadow-the-market-drowns-society-in-a-bat": _temp483,
  "the-new-development-is-that-there-no-jobs-developing": _temp484,
  "the-new-machine-performance-matters": _temp485,
  "the-new-york-primary-its-not-likely-to-change-anything": _temp486,
  "the-next-9-11": _temp487,
  "the-other-half-of-a-conspiracy": _temp488,
  "the-parcc-is-a-public-private-partnership-what-if-the-market-isnt-failing-i": _temp489,
  "the-phantom-menacing-the-ctu-strike-rahms-faux-negotiations": _temp490,
  "the-plight-of-an-american-moderate": _temp491,
  "the-police-reform-monkey-dance-goes-freestyle": _temp492,
  "the-political-theater-of-nickels-and-dimes": _temp493,
  "the-potemkin-candidate": _temp494,
  "the-present-mayoral-race-part-1-the-crack-chicago-stenography-corps-is-not": _temp495,
  "the-present-mayoral-race-part-2-the-good-the-bad-and-the-ugly-of-the-candid": _temp496,
  "the-president-doesnt-want-to-be-re-elected": _temp497,
  "the-presumptive-tax-dodger": _temp498,
  "the-public-policy-of-plutocrats-predators-the-charity-of-monsters": _temp499,
  "the-quality-school-day": _temp500,
  "the-rahmbo-narrative-vs.-the-emanuel-reality": _temp501,
  "the-responsible-budget-ordinance": _temp502,
  "the-road-to-five-hundred-plus": _temp503,
  "the-same-side-of-an-ugly-coin": _temp504,
  "the-shuck-and-jive-of-thirty-pieces-of-silver-returns": _temp505,
  "the-state-of-our-city": _temp506,
  "the-stealth-convention": _temp507,
  "the-stupidity-of-cupidity": _temp508,
  "the-sun-times-and-tribune-killing-democracy-one-column-inch-at-a-time": _temp509,
  "the-tea-party-made-clear": _temp510,
  "the-teachers-strike-reminds-us-which-side-rahms-always-been-on": _temp511,
  "the-ten-females-who-cost-mitt-romney-the-presidency": _temp512,
  "the-third-poll": _temp513,
  "the-top-ten-comedic-news-stories-of-2011": _temp514,
  "the-trauma-of-socioeconomic-violence": _temp515,
  "the-tutorial-picks-the-primaries": _temp516,
  "the-victory-speech-should-worry-you": _temp517,
  "the-whittier-lessons": _temp518,
  "the-wrong-stuff": _temp519,
  "the-wrongest-side-of-history": _temp520,
  "there-is-no-business-model-for-public-safety": _temp521,
  "there-is-nothing-civic-about-the-civic-federation-part-50": _temp522,
  "there-is-nothing-non-partisan-about-the-civic-federation": _temp523,
  "theres-no-conflict-after-a-hostile-takeover": _temp524,
  "theres-nothing-funny-about-these-candidates": _temp525,
  "thinking-like-a-business-is-bad-public-policy": _temp526,
  "thirteen-ways-of-looking-at-the-occupation-apologies-to-wallace-stevens": _temp527,
  "three-words-do-not-an-issue-make-kicking-page-may-as-cover-for-beating-on-c": _temp528,
  "tina-fails-again-fiscal-policy-in-emanuel-land": _temp529,
  "to-fight-them": _temp530,
  "to-fight-them1": _temp531,
  "today-we-organize": _temp532,
  "tone-deaf-tin-eared-borg": _temp533,
  "tone-deaf-tin-eared-borg1": _temp534,
  "toni-vs.-rahm-a-tale-of-wolves-xenomorphs": _temp535,
  "tough-guys-dance-but-dont-run": _temp536,
  "transformation-not-reformation-we-need-more-than-a-mayor": _temp537,
  "transparency": _temp538,
  "tribunes-national-news-leaves-citizen-voters-helpless-and-uninformed": _temp539,
  "trickle-up-economics": _temp540,
  "trust-me-really": _temp541,
  "turkey-holocaust-day-2011": _temp542,
  "twilight-of-integrity": _temp543,
  "understanding-money": _temp544,
  "understanding-the-un-accountability-monkey-dance-on-copa": _temp545,
  "vandals-at-the-public-gates-and-inside-the-citys-walls": _temp546,
  "ventra-a-micro-example-of-macro-loan-sharking": _temp547,
  "veterans-day": _temp548,
  "vetting-for-the-wrong-thing": _temp549,
  "voiceless": _temp550,
  "wal-mart-advocate-runs-african-american-caucus": _temp551,
  "war-on-the-future-chicago-public-schools-cps-tries-to-free-itself-of-public": _temp552,
  "watch-the-hungry-fight-for-crumbs-in-the-rubble": _temp553,
  "watching-the-watchers": _temp554,
  "we-are-how-we-behave": _temp555,
  "we-are-the-hungry-in-rubble": _temp556,
  "we-dont-need-no-education-same-as-it-ever-was-worse-than-you-think": _temp557,
  "we-must-rally": _temp558,
  "we-need-police-transformation-not-mayor-emanuels-head-but-that-would-be-nic": _temp559,
  "we-tire-of-the-constitution-time-to-leave-the-18th-century": _temp560,
  "wearing-my-debate-fatigues": _temp561,
  "weasel-boy-versus-plastic-man": _temp562,
  "weinergate": _temp563,
  "were-all-muppets-here": _temp564,
  "whack-a-pol": _temp565,
  "what-cha-tells-us-about-public-policy": _temp566,
  "what-is-this-g8-thing-people-are-talking-about-and-why-should-i-care": _temp567,
  "what-needs-watching": _temp568,
  "what-questions-reveal": _temp569,
  "what-we-learned-today-bad-reporting-just-gets-worse": _temp570,
  "what-went-wrong-out-there-or-how-an-angry-marmoset-missed-a-branch": _temp571,
  "when-a-ceo-mayor-cant-do-math": _temp572,
  "when-no-one-knows-nothing-polling-ninja-dust-the-bottomless-stupidity-of-th": _temp573,
  "when-numbers-mean-nothing": _temp574,
  "when-will-the-question-measure-up-to-the-subject": _temp575,
  "while-we-react-to-physical-police-violence-rahms-financial-partners-do-fisc": _temp576,
  "whittier-parents-and-activists": _temp577,
  "who-wins-why": _temp578,
  "why-there-is-no-left-wing-tea-party": _temp579,
  "why-theres-no-reform-in-the-mayors-budget": _temp580,
  "will-dursts-2013-political-animal-awards": _temp581,
  "will-durt-2011-xma-gift-wih-lit": _temp582,
  "will-no-one-rid-me-of-this-turbulent-priest": _temp583,
  "will-the-city-that-works-start-working-for-african-americans": _temp584,
  "worst-campaign-ever": _temp585,
  "yes-virginia-some-men-are-pigs": _temp586
}