const _temp0 = require("../../accountability-corner/pagination/page-1.json");

const _temp1 = require("../../candidate-watch/pagination/page-1.json");

const _temp2 = require("../../hackfest/pagination/page-1.json");

const _temp3 = require("../../homework/pagination/page-1.json");

const _temp4 = require("../../machine-shop/pagination/page-1.json");
const _temp5 = require("../../machine-shop/pagination/page-2.json");

const _temp6 = require("../../notes-from-the-campaign-trail/pagination/page-1.json");

const _temp7 = require("../../political-arts/pagination/page-1.json");

const _temp8 = require("../../political-spotlight/pagination/page-1.json");

const _temp9 = require("../../rants/pagination/page-1.json");

const _temp10 = require("../../view-from-washington/pagination/page-1.json");
const _temp11 = require("../../view-from-washington/pagination/page-2.json");

module.exports = {
  "accountability-corner":   {
    "page-1": _temp0
  },
  "candidate-watch":   {
    "page-1": _temp1
  },
  "hackfest":   {
    "page-1": _temp2
  },
  "homework":   {
    "page-1": _temp3
  },
  "machine-shop":   {
    "page-1": _temp4,
    "page-2": _temp5
  },
  "notes-from-the-campaign-trail":   {
    "page-1": _temp6
  },
  "political-arts":   {
    "page-1": _temp7
  },
  "political-spotlight":   {
    "page-1": _temp8
  },
  "rants":   {
    "page-1": _temp9
  },
  "view-from-washington":   {
    "page-1": _temp10,
    "page-2": _temp11
  }
}