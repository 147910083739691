const _temp0 = require("./an-introduction-to-public-policy.json");
const _temp1 = require("./budgetary-reform.json");
const _temp2 = require("./economic-development-concepts.json");
const _temp3 = require("./education-policy.json");
const _temp4 = require("./hallmarks-of-good-budget-and-taxation-policy.json");
const _temp5 = require("./public-safety.json");
const _temp6 = require("./the-principles-of-pension-reform.json");
const _temp7 = require("./the-tutorials-public-policy-propaganda-inoculation-kit.json");
const _temp8 = require("./tif-reform.json");
module.exports = {
  "an-introduction-to-public-policy": _temp0,
  "budgetary-reform": _temp1,
  "economic-development-concepts": _temp2,
  "education-policy": _temp3,
  "hallmarks-of-good-budget-and-taxation-policy": _temp4,
  "public-safety": _temp5,
  "the-principles-of-pension-reform": _temp6,
  "the-tutorials-public-policy-propaganda-inoculation-kit": _temp7,
  "tif-reform": _temp8
}