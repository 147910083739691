import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { anchors } from '../css/anchors.css';
import { typography } from "../css/type.css";
import { widgets } from "../css/widgets.css";

@customElement('widget-content-list')
export class SidebarWidget extends LitElement {
  @property() content = "";
  @property() contentType = "";
  @property() rootPath="";
  @property() widgetType = "";
  
  static get styles() {
    return [
      anchors,
      typography,
      widgets,
      css``
    ]
  }

  render() {
    return html`
<ul>
  ${JSON.parse(this.content).map(c => {
    const url = new URL(`${document.location.origin}${this.rootPath !== "" ? this.rootPath : `/${this.contentType}/${this.widgetType}/`}${c.slug}`);
    
    return html`<li><a href="${url}">${c.name}</a></li>`})}
</ul>
`
  }
}