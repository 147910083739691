const _temp0 = require("../../jesus-chuy-garcia/pagination/page-1.json");

const _temp1 = require("../../rahm-emanuel/pagination/page-1.json");
const _temp2 = require("../../rahm-emanuel/pagination/page-2.json");
const _temp3 = require("../../rahm-emanuel/pagination/page-3.json");

const _temp4 = require("../../william-dock-walls/pagination/page-1.json");

module.exports = {
  "jesus-chuy-garcia":   {
    "page-1": _temp0
  },
  "rahm-emanuel":   {
    "page-1": _temp1,
    "page-2": _temp2,
    "page-3": _temp3
  },
  "william-dock-walls":   {
    "page-1": _temp4
  }
}