import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { anchors } from '../css/anchors.css';
import { typography } from "../css/type.css";
import { widgets } from "../css/widgets.css";
import interfaceImages from "../images/interface/*.png";

@customElement('sidebar-widget')
export class SidebarWidget extends LitElement {
  @property() title = "";
  
  static get styles() {
    return [
      anchors,
      typography,
      widgets,
      css``
    ]
  }
  render() {
    return html`
      <header>
        <h2 class="entry-title">${this.title}</h2>
        <img src=${interfaceImages["mt-star-black"]} alt="Sidebar Widget Star">
      </header>
      <div class="entry-content">
        <slot></slot>
      </div>
    `;
  }
}