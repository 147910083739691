export const reportCardsSubPages = {
  "title": "Report Cards",
  "rootPath": "/policy-promises/report-cards/",
  "content": [
    {
      "name": "Rahm's First Report Card",
      "slug": "rahms-first-report-card"
    }
  ]
}
