const _temp0 = require("./body-wht.png");
const _temp1 = require("./content-title-bar.png");
const _temp2 = require("./hz-body.png");
const _temp3 = require("./hz-sidebar.png");
const _temp4 = require("./mt-404-img.png");
const _temp5 = require("./mt-404.png");
const _temp6 = require("./mt-apple-touch-icon.png");
const _temp7 = require("./mt-background.png");
const _temp8 = require("./mt-don-wash-solid.png");
const _temp9 = require("./mt-don-wash.png");
const _temp10 = require("./mt-fb-b.png");
const _temp11 = require("./mt-fb-r.png");
const _temp12 = require("./mt-ico-lrg.png");
const _temp13 = require("./mt-mast.png");
const _temp14 = require("./mt-masthead.png");
const _temp15 = require("./mt-returns.png");
const _temp16 = require("./mt-scheduled-maintenance.png");
const _temp17 = require("./mt-side-title-bar.png");
const _temp18 = require("./mt-ss1.png");
const _temp19 = require("./mt-ss2.png");
const _temp20 = require("./mt-star-black.png");
const _temp21 = require("./mt-star-red.png");
const _temp22 = require("./mt-star.png");
const _temp23 = require("./mt-twitt-b.png");
const _temp24 = require("./mt-twitt-r.png");
const _temp25 = require("./sidebar-wht.png");
module.exports = {
  "body-wht": _temp0,
  "content-title-bar": _temp1,
  "hz-body": _temp2,
  "hz-sidebar": _temp3,
  "mt-404-img": _temp4,
  "mt-404": _temp5,
  "mt-apple-touch-icon": _temp6,
  "mt-background": _temp7,
  "mt-don-wash-solid": _temp8,
  "mt-don-wash": _temp9,
  "mt-fb-b": _temp10,
  "mt-fb-r": _temp11,
  "mt-ico-lrg": _temp12,
  "mt-mast": _temp13,
  "mt-masthead": _temp14,
  "mt-returns": _temp15,
  "mt-scheduled-maintenance": _temp16,
  "mt-side-title-bar": _temp17,
  "mt-ss1": _temp18,
  "mt-ss2": _temp19,
  "mt-star-black": _temp20,
  "mt-star-red": _temp21,
  "mt-star": _temp22,
  "mt-twitt-b": _temp23,
  "mt-twitt-r": _temp24,
  "sidebar-wht": _temp25
}