import { fetchData } from "./fetch-data";
import { renderPage } from "./render-page";
import list from '../layouts/list';
// @ts-ignore
import * as politicalGraveyardPages from '../political-graveyard/pagination/*.json';
// @ts-ignore
import * as pages from '../pages/*.json';

export const fetchPoliticalGraveyardPages = (context, url=`${politicalGraveyardPages["page-1"]}`) => fetchData(new URL(url, import.meta.url), {
  ...context,
  contentType: "political-graveyard"
}).then(async data => {
  const categories = await fetchData(new URL("../articles/categories.json", import.meta.url));
  const candidates = await fetchData(new URL("../articles/candidates.json", import.meta.url));
  const bookplateArticle = await fetchData(new URL(pages["political-graveyard"]));
  const currentPage = context.path.startsWith('/page-') ? Number(context.path.replace('/page-', '')) : 1;

  return renderPage(
    list, {
      articles: data,
      bookplateArticle,
      candidates,
      categories,
      currentPage,
      pagination: politicalGraveyardPages
    },
    {
      ...context,
      contentType: "political-graveyard"
    }
  );
});
