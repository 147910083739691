import { css } from 'lit';

export const typography = css`
*,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font: normal normal 400 1em/1.5em schoolbook-web-1,schoolbook-web-2,serif;
}

p {
    color: #333;
}
`;
