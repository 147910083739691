import { fetchData } from "./fetch-data";
import { renderPage } from "./render-page";
import list from '../layouts/list';
// @ts-ignore
import * as articlePagination from '../articles/pagination/*.json';

export const fetchArticlePages = (context, url=`${articlePagination["page-1"]}`) => fetchData(new URL(url, import.meta.url), context)
  .then(async data => {
    const categories = await fetchData(new URL("../articles/categories.json", import.meta.url));
    const candidates = await fetchData(new URL("../articles/candidates.json", import.meta.url));
    const currentPage = context.path.startsWith('/page-') ? Number(context.path.replace('/page-', '')) : 1;

    return renderPage(
      list, {
        articles: data.slice(1),
        candidates,
        categories,
        currentPage,
        featured: data[0],
        pagination: articlePagination
      },
      context,
    );
  });
