import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { anchors } from '../css/anchors.css';
import { typography } from "../css/type.css";
import { widgets } from "../css/widgets.css";
import '../components/SidebarWidget';

@customElement('sidebar-widgets')
export class SidebarWidgets extends LitElement {
  @property() content = [];

  static get styles() {
    return [
      anchors,
      typography,
      widgets,
      css`
        .social a {
          margin-right: 1em;
          width: 30%;
        }

        .social img {
          max-width: 100%;
        }

        .social img:nth-of-type(1) {
          display: block;
        }

        .social img:nth-of-type(2) {
          display: none;
        }

        .social a:hover img:nth-of-type(1) {
          display: none;
        }

        .social a:hover img:nth-of-type(2) {
          display: block;
        }

        .social .entry-content {
          background: none;
          display: flex;
        }
      `
    ];
  }

  render() {
    return html`
<sidebar-widget name="next-event" title="Next Event">
  <p>There are no presently scheduled events.</p>
  <p>If you want to be Dangerously Informed on some piece of public policy, the Tutorial can come to you.  Reach out to us at <a href="mailto:don.washington@mayoraltutorial.com">jack@mayoraltutorial.com</a></p>
</sidebar-widget>
<article class="social widget">
  <div class="entry-content">
    <a class="facebook-link" href="https://www.facebook.com/mayoral.tutorial?ref=ts" title="Check out our Facebook Page"><img class="top" src="/images/interface/mt-fb-b.png" alt="FACEBOOK"><img class="bottom" src="/images/interface/mt-fb-r.png" alt="FACEBOOK"></a>
    <a class="twitter-link" href="https://twitter.com/#!/Drobsidian" title="Follow us on Twitter"><img class="top" src="/images/interface/mt-twitt-b.png" alt="TWITTER"><img class="bottom" src="/images/interface/mt-twitt-r.png" alt="TWITTER"></a>
  </div>
</article>
<slot></slot>
<sidebar-widget class="additional-reading" title="Additional Reading">
  <ul>
    <li><a href="http://vocalo.org/barber-shop-show/2011-01/barber-shop-show-lets-get-dangerously-informed">Barbershop Show</a></li>
    <li><a href="http://capitolfax.com/">Capitol Fax</a></li>
    <li><a href="http://www.chicagoreporter.com/">Chicago Reporter</a></li>
    <li><a href="http://newsblogs.chicagotribune.com/clout_st/2011-chicago-mayors-race/">Clout Street</a></li>
    <li><a href="http://www.facebook.com/pages/Democracy-Burlesque/70328599013?v=box_3">Democracy-Burlesque</a></li>
    <li><a href="http://www.epi.org/">Economic Policy Institute</a></li>
    <li><a href="http://gapersblock.com/">Gapers Block</a></li>
    <li><a href="http://itismymind.blogspot.com/2011/01/black-politics-in-chicago-2011.html">It's My Mind</a></li>
    <li><a href="http://leftcheek.blogspot.com/2011/01/chicago-tuesdays-viability-issue.html">Left Cheek</a></li>
    <li><a href="http://www.ourcampaigns.com/RaceDetail.html?RaceID=481786">Our Campaigns</a></li>
    <li><a href="http://www.pdaillinois.org/site/content/issues-are-key-chicago-mayoral-race">Progressive Democrats for America – Illinois</a></li>
    <li><a href="http://www.elections.il.gov/campaigndisclosure/CandidateSearch.aspx">State of Illinois Disclosure Search</a></li>
    <li><a href="http://www.windycitizen.com/talkativetree">Talkative Tree</a></li>
    <li><a href="http://www.nbcchicago.com/blogs/ward-room/">The Ward Room</a></li>
    <li><a href="http://www.windycitizen.com/chicago/2011/01/04/mayoral-tutorial">Windy Citizen</a></li>
  </ul>
</sidebar-widget>
  `;
  }
}