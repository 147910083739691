import { css } from 'lit';

export const bookplateStyles = css`
  :host {
    display: flex;
    padding: 3%;
  }

  article.has-bookplate-image {
    padding-right: 3%;
    width: 57%;
  }

  h2, p, h2 a {
    display: inline;
    font-size: 1.2em;
    line-height: 1.4em;
  }

  aside {
    width: 40%;
  }

  aside a {
    text-align: right;
  }

  aside img {
    max-width: 100%;
  }
`;