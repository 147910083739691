import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { anchors } from '../css/anchors.css.ts';

function getOrdinalNum(n) {
  return (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  var strTime = ampm;
  return strTime;
}

const mastheadImage = new URL("../images/interface/mt-masthead.png", import.meta.url);

// Date builder materials
const months = ["January","February","March","April","May","June","July","August","September","October","November","December"]; 
const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
const d = new Date();

// Resolved date segments
const day = weekday[d.getDay()];
const month = months[d.getUTCMonth()];
const date = d.getDate();
const ordinal = getOrdinalNum(d.getDate());
const year = d.getUTCFullYear();
const hour = d.getHours() > 12
  ? d.getHours() - 12
  : d.getHours();
const minute = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
const ampm = formatAMPM(d);

@customElement('page-header')
export class PageHeader extends LitElement {
  static get styles() {
    return [
      anchors,
      css`
        :host {
          display: block;
          margin-bottom: 1em;
          position: relative;
        }

        a {
          display: block;
          color: #444;
          font-size: 1.3em;
          padding-top: 1em;
          text-align: center;
          text-decoration: none;
          width: 100%;
        }

        img {
          max-width: 100%;
        }

        sub {
          bottom: 0;
          vertical-align: baseline;
        }

        sup {
          font-size: 0.5em;
          top: 0;
          vertical-align: super;
        }

        sub, sup {
          font-size: 0.5em;
        }

        time {
          display: block;
          font-size: 1.3em;
          position: relative;
          text-align: center;
          text-transform: uppercase;
        }
      `
    ];
  }

  render() {
    return html`
      <a href="/">DON WASHINGTON&#39;S</a>
      <img src=${mastheadImage} alt="The Mayoral Tutorial Masthead Image" />
      <time>${day}, ${month} ${date}<sup>${ordinal}</sup>, ${year} &bull; ${hour}:${minute}<sub>${ampm}</sub></time>
    `;
  } 
}
