import { fetchData } from "./fetch-data";
import { renderPage } from "./render-page";
import single from '../layouts/single';

// @ts-ignore
import * as policyPromisesPages from '../policy-promises/pagination/*.json';
// @ts-ignore
import * as policyPromises from '../policy-promises/*.json';
import { reportCardsSubPages } from '../report-cards/sub-pages';

export const fetchPolicyPromise = context => fetchData(new URL(policyPromises[context.params.name], import.meta.url), context)
  .then(async data => {
    const categories = await fetchData(new URL(policyPromises["categories"], import.meta.url));
    const bookplateArticle = data.categories.length > 0 ? data.categories[0][1] : undefined;

    return renderPage(
      single, {
        articles: data,
        bookplateArticle,
        categories,
        currentPage: 1,
        pagination: policyPromisesPages,
        subPages: reportCardsSubPages
      },
      {
        ...context,
        contentType: "policy-promises"
      }
    );
  });
