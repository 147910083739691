import { fetchData } from "./fetch-data";
import { renderPage } from "./render-page";
import list from '../layouts/list';
// @ts-ignore
import * as candidatePagination from '../articles/candidate/*/pagination/*.json';
// @ts-ignore
import * as categoryPagination from '../articles/category/*/pagination/*.json';
// @ts-ignore
import * as graveyardCandidatePagination from '../political-graveyard/candidate/*/pagination/*.json';
// @ts-ignore
import * as graveyardCategoryPagination from '../political-graveyard/category/*/pagination/*.json';

export const fetchArticleCategoryPages = (context, url) => {
  const categorySlug = context.path.replaceAll('/', '');
  const pagination = context.pathname.includes('candidate')
    ? candidatePagination[categorySlug]
    : categoryPagination[categorySlug]
  
  const graveyardPagination = context.pathname.includes('candidate')
  ? graveyardCandidatePagination[categorySlug]
  : graveyardCategoryPagination[categorySlug]

  const { contentType } = context;

  return fetchData(new URL(url, import.meta.url), context)
  .then(async data => {
    const categories = await fetchData(new URL(`../articles/categories.json`, import.meta.url));
    const candidates = await fetchData(new URL(`../articles/candidates.json`, import.meta.url));
    const currentPage = context.pathname.includes('/page-') ? Number(context.pathname.split('/').filter(s => s.startsWith('page-'))[0].replace('page-', '')) : 1;
    const categoryArticle = categories.find((c) => c.slug === categorySlug)
    
    return renderPage(
      list, {
        articles: data,
        candidates,
        categories,
        currentPage,
        bookplateArticle: categoryArticle,
        pagination: contentType === 'articles'
          ? pagination
          : graveyardPagination
      },
      context,
    );
  });
}
