import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators";
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { typography } from "../css/type.css";
import { anchors } from "../css/anchors.css";
import { bookplateStyles } from "../css/bookplate-article.css";
import * as images from "../images/category/*.png";

@customElement('bookplate-article')
export class bookplateArticle extends LitElement {
  @property() content = ""

  static get styles() {
    return [
      anchors,
      typography,
      bookplateStyles,
      css``
    ];
  }

  render() {
    const {name, contents, description, excerpt, slug} = JSON.parse(this.content);
    const categoryImage = `${slug}-plate`;
    const categoryImageSrc = images[categoryImage] ? new URL(images[categoryImage]) : undefined;
    const categoryImageAlt = `Category Bookplate Image for ${name}`;
    
    const useContents = contents && contents !== "" ? contents : false;
    const useDescription = description && description !== "" ? `<p>${description}</p>` : false;
    const useExcerpt = excerpt && excerpt !== "" ? `<p>${excerpt}</p>` : false;
    const bookplateText = useDescription || useExcerpt || useContents || "";

    return categoryImageSrc ? html`
      <article class="has-bookplate-image">
        ${name ? html`<h2><a href="/articles/category/${slug}" title="link to articles from the ${name} category">${name}</a></h2> - ` : ''}
        ${unsafeHTML(bookplateText)}
      </article>
      <aside>
        <a href="/articles/category/${slug}" title="link to articles from the ${name} category"><img src="${categoryImageSrc}" alt="${categoryImageAlt}" /></a>
      </aside>`
      : html`
      <article>
        ${name ? html`<h2><a href="/articles/category/${slug}" title="link to articles from the ${name} category">${name}</a></h2> - ` : ''}
        ${unsafeHTML(bookplateText)}
      </article>
    `;
  }
}