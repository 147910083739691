export const publicPolicy101SubPages = {
  "title": "Public Policy 101",
  "rootPath": "/public-policy-101/",
  "content": [
    {
      "name": "An Introduction To Public Policy",
      "slug": "an-introduction-to-public-policy"
    },
    { 
      "name": "Hallmarks Of Good Budget And Taxation Policy",
      "slug": "hallmarks-of-good-budget-and-taxation-policy"
    },
    {
      "name": "The Tutorials Public Policy Propaganda Inoculation Kit",
      "slug": "the-tutorials-public-policy-propaganda-inoculation-kit"
    },
    {
      "name": "Budgetary Reform",
      "slug": "budgetary-reform"
    },
    {
      "name": "Economic Development Concepts",
      "slug": "economic-development-concepts"
    },
    {
      "name": "Education Policy",
      "slug": "education-policy"
    },
    {
      "name": "Public Safety",
      "slug": "public-safety"
    },
    {
      "name": "The Principles Of Pension Reform",
      "slug": "the-principles-of-pension-reform"
    },
    {
      "name": "Tif Reform",
      "slug": "tif-reform"
    }
  ]
}
