import { fetchData } from "./fetch-data";
import { renderPage } from "./render-page";
import list from '../layouts/list';
// @ts-ignore
import * as policyPromisesPages from '../policy-promises/pagination/*.json';
// @ts-ignore
import * as pages from '../pages/*.json';
import { reportCardsSubPages } from '../report-cards/sub-pages'

export const fetchPolicyPromisesPages = context => fetchData(new URL("../policy-promises/pagination/page-1.json", import.meta.url), {
  ...context,
  contentType: "policy-promises"
})
.then(async data => {
  const categories = await fetchData(new URL("../policy-promises/categories.json", import.meta.url));
  const bookplateArticle = await fetchData(new URL(pages["policy-promises"]));

  return renderPage(
    list, {
      articles: data,
      bookplateArticle,
      categories,
      currentPage: 1,
      pagination: policyPromisesPages,
      subPages: reportCardsSubPages
    },
    {
      ...context,
      contentType: "policy-promises"
    });
});
