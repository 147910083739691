import UniversalRouter from 'universal-router';
import {LitElement, html, render} from 'lit';
import {customElement, property} from 'lit/decorators.js';

import list from './layouts/list';
import single from './layouts/single';

import { fetchArticle } from './lib/fetch-article';
import { fetchArticlePages } from './lib/fetch-article-pages';
import { fetchArticleCategoryPages } from './lib/fetch-article-category-pages';
import { fetchPolicyPromise } from './lib/fetch-policy-promise';
import { fetchPolicyPromiseCategoryPages } from './lib/fetch-policy-promises-category-pages';
import { fetchPolicyPromisesPages } from './lib/fetch-policy-promises-pages';
import { fetchPoliticalGraveyardArticle } from './lib/fetch-political-graveyard-article';
import { fetchPoliticalGraveyardPages } from './lib/fetch-political-graveyard-pages';
import { fetchData } from './lib/fetch-data';
import { getPageContext } from './lib/get-page-context';
import { getRoutes } from './lib/get-routes';
import { renderPage } from './lib/render-page';

// @ts-ignore
import * as articles from './articles/*.json';
// @ts-ignore
import * as articlePagination from './articles/pagination/*.json';
// @ts-ignore
import * as articleCandidatePagination from './articles/candidate/*/pagination/*.json';
// @ts-ignore
import * as articleCategoryPagination from './articles/category/*/pagination/*.json';
// @ts-ignore
import * as graveyardCandidatePagination from './political-graveyard/candidate/*/pagination/*.json';
// @ts-ignore
import * as graveyardCategoryPagination from './political-graveyard/category/*/pagination/*.json';
// @ts-ignore
import * as pages from './pages/*.json';
// @ts-ignore
import * as policyPromisesPagination from './policy-promises/pagination/*.json';
// @ts-ignore
import * as policyPromiseCategoryPagination from './policy-promises/category/*/*.json';
// @ts-ignore
import * as politicalGraveyardPagination from './political-graveyard/pagination/*.json';
// @ts-ignore
import * as publicPolicy101 from './public-policy-101/*.json';
import { publicPolicy101SubPages } from './public-policy-101/sub-pages';
import { fetchReportCard } from './lib/fetch-report-card';

const routes = [
  {
    name: 'front page',
    path: '/',
    parentPath: '',
    action: context => fetchArticlePages(
      {
        ...context,
        contentType: "articles"
      }),
  },
  {
    name: 'articles',
    path: '/articles',
    action: () => {},
    children: [
      {
        path: '/:name',
        parentPath: '/articles',
        action: context => fetchArticle({
          ...context,
          contentType: "articles"
        }),
      },
      {
        path: '/candidate',
        action: () => {},
        parentPath: '/articles',
        children: [
          {
            path: '/:candidate',
            parentPath: '/candidate',
            action: context => {
              const candidate = context.path.replaceAll('/', '')
              const url = articleCandidatePagination[candidate]['page-1'];
              return fetchArticleCategoryPages({
                ...context,
                contentType: "articles"
              }, url)
            },
            children: [
              {
                name: 'article pagination',
                path: '/pagination',
                parentPath: '/:category',
                action: () => {},
                children: [
                  {
                    path: '/:page',
                    parentPath: '/pagination',
                    action: context => fetchArticleCategoryPages({
                      ...context,
                      contentType: "articles"
                    }, articleCandidatePagination[context.params.page])
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        path: '/category',
        parentPath: '/articles',
        action: () => {},
        children: [
          {
            path: '/:category',
            parentPath: '/category',
            action: context => {
              const category = context.path.replaceAll('/', '')
              const url = articleCategoryPagination[category]['page-1'];
              return fetchArticleCategoryPages({
                ...context,
                contentType: "articles"
              }, url)
            },
            children: [
              {
                name: 'article pagination',
                path: '/pagination',
                parentPath: '/:category',
                action: () => {},
                children: [
                  {
                    path: '/:page',
                    parentPath: '/pagination',
                    action: context => fetchArticlePages({
                      ...context,
                      contentType: "articles"
                    }, articleCategoryPagination[context.params.page])
                  }
                ]
              },
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'article pagination',
    path: '/pagination',
    action: () => {},
    children: [
      {
        path: '/:page',
        parentPath: '/pagination',
        action: context => fetchArticlePages({
          ...context,
          contentType: "articles"
        }, articlePagination[context.params.page])
      }
    ]
  },
  {
    name: 'policy promises',
    parentPath: '',
    path: '/policy-promises',
    action: () => {},
    children: [
      {
        path: '',
        parentPath: '/policy-promises',
        action: context => fetchPolicyPromisesPages(
          {
            ...context,
            contentType: "policy-promises"
          }),
      },
      {
        path: '/:name',
        parentPath: '/policy-promises',
        action: context => fetchPolicyPromise({
          ...context,
          contentType: "policy-promises"
        })
      },
      {
        path: '/category',
        parentPath: '/policy-promises',
        action: () => {},
        children: [    
          {
            path: '/:category',
            parentPath: '/category',
            action: context => {
              const category = context.path.replaceAll('/', '')
              const url = policyPromiseCategoryPagination[category]['page-1'];
              return fetchPolicyPromiseCategoryPages({
                ...context,
                contentType: "policy-promises"
              }, url)
            }
          },  
        ]
      },
      {
        path: '/report-cards',
        parentPath: '/policy-promises',
        action: () => {},
        children: [
          {
            path: '/:name',
            parentPath: '/report-cards',
            action: context => fetchReportCard(
              {
                ...context,
                contentType: "report-cards"
              }
            ),
          }
        ]
      },
    ]
  },
  {
    name: 'public policy 101',
    parentPath: '',
    path: '/public-policy-101',
    action: () => {},
    children: [
      {
        path: "",
        parentPath: "/public-policy-101",
        action: context => fetchData(new URL(`./public-policy-101/an-introduction-to-public-policy.json`, import.meta.url), context)
        .then(async data => {
          const bookplateArticle = await fetchData(new URL(pages["public-policy-101"]));
          const subPages = publicPolicy101SubPages;

          return renderPage( single, { articles: data, bookplateArticle, subPages }, context );
        })
      },
      {
        path: "/:name",
        parentPath: "/public-policy-101",
        action: context => {
          
          return fetchData(new URL(publicPolicy101[context.params.name], import.meta.url), context)
          .then(async data => {
            const bookplateArticle = await fetchData(new URL(pages["public-policy-101"]));
            const subPages = publicPolicy101SubPages;

            return renderPage( single, { articles: data, bookplateArticle, subPages }, context );
          })}
      }
    ]
  },
  {
    name: 'political graveyard',
    parentPath: '',
    path: '/political-graveyard',
    action: () => {},
    children: [
      {
        path: "",
        parentPath: '/political-graveyard',
        action: context => fetchPoliticalGraveyardPages({
          ...context,
          contentType: "political-graveyard"
        }, politicalGraveyardPagination[context.params.page])
      },
      {
        path: '/:name',
        parentPath: '/political-graveyard',
        action: context => fetchPoliticalGraveyardArticle(context)
      },
      {
        path: '/candidate',
        action: () => {},
        parentPath: '/political-graveyard',
        children: [
          {
            path: '/:candidate',
            parentPath: '/candidate',
            action: context => {
              const candidate = context.path.replaceAll('/', '')
              const url = graveyardCandidatePagination[candidate]['page-1'];
              return fetchArticleCategoryPages({
                ...context,
                contentType: "political-graveyard"
              }, url)
            },
            children: [
              {
                name: 'graveyard pagination',
                path: '/pagination',
                parentPath: '/:category',
                action: () => {},
                children: [
                  {
                    path: '/:page',
                    parentPath: '/pagination',
                    action: context => fetchArticleCategoryPages({
                      ...context,
                      contentType: "political-graveyard"
                    }, graveyardCandidatePagination[context.params.page])
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        path: '/category',
        parentPath: '/political-graveyard',
        action: () => {},
        children: [
          {
            path: '/:category',
            parentPath: '/category',
            action: context => {
              const category = context.path.replaceAll('/', '')
              const url = graveyardCategoryPagination[category]['page-1'];
              return fetchArticleCategoryPages({
                ...context,
                contentType: "political-graveyard"
              }, url)
            },
            children: [
              {
                name: 'article pagination',
                path: '/pagination',
                parentPath: '/:category',
                action: () => {},
                children: [
                  {
                    path: '/:page',
                    parentPath: '/pagination',
                    action: context => fetchArticlePages({
                      ...context,
                      contentType: "political-graveyard"
                    }, graveyardCategoryPagination[context.params.page])
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        name: 'political graveyard pagination',
        path: '/pagination',
        parentPath: '/political-graveyard',
        action: () => {},
        children: [
          {
            path: '/:page',
            parentPath: '/pagination',
            action: context => fetchPoliticalGraveyardPages({
              ...context,
              contentType: "political-graveyard"
            }, politicalGraveyardPagination[context.params.page])
          }
        ]
      }
    ]
  },
  {
    name: 'about us',
    parentPath: '',
    path: '/about-us',
    action: context => fetchData(new URL(`./pages/about-us.json`, import.meta.url), context)
      .then(async data => {
        const bookplateArticle = await fetchData(new URL(pages["about-us"]));
        return renderPage(single, {articles: data, bookplateArticle}, context)
    })
  }
];

export default new UniversalRouter(routes);
