import { LitElement, html, css } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { anchors } from '../css/anchors.css';
import { articleStyles } from '../css/article.css';
import { typography } from "../css/type.css";

const baseURL = import.meta.url;
const redStar = new URL("../images/interface/mt-star-red.png", import.meta.url);

@customElement('single-page')
export class SinglePage extends LitElement {
  @property() content = "";

  static get styles() {
    return [
      anchors,
      articleStyles,
      typography,
      css`
header h2 {
  padding-left: 4.2905028%;
  width: 85%;
}

.entry-content {
  padding: 1em 4.2905028% 2em;
}
      `
    ]
  }

  render() {
    const { author, contents, createDate, editDate, path, slug, title } = JSON.parse(this.content)

    const cd = new Date(createDate);
    const cdyear = cd.getUTCFullYear();
    const cdmonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(cd);
    const cddate = cd.getDate();
    const ed = new Date(editDate);
    const edyear = ed.getUTCFullYear();
    const edmonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(ed);
    const eddate = ed.getDate();

    return html`
<article>
  <header>
    <h2>${title}</h2>
    <img src=${ redStar } alt="Sidebar Widget Star">
  </header>
  <div class="entry-content">
    <div class="article-body">
      ${unsafeHTML(contents)}
    </div>
  </div>
</article>
`;
  }
}