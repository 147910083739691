import { css, unsafeCSS } from 'lit';

import interfaceImages from "../images/interface/*.png";

export const articleStyles = css`
:host {
  display: grid;
}

a {
  color: var(--red)
}

h2 a, h2 a:visited {
  color: var(--text-color);
}

header {
  background: url(${unsafeCSS(interfaceImages["content-title-bar"])}) top left/100% auto repeat-y;
  background-size: 100%;
  max-width: 100%;
  padding: .5em 0;
}

h2 {
  margin: 0;
}

header,
.entry-content {
  display: flex;
  align-items: flex-start;
}

header h2 {
  font-size: 1.7em;
  line-height: 1.3em;
}

header h2 a:hover {
  color: var(--text-color);
}

header img {
  margin: 0.2em 0 0 2%;
  width: 4.5%;
}

p {
  font-size: 1.2em;
  line-height: 1.3em;
  margin: 0 0 1em 0;
}

a, p {
  display: inline;
}

.entry-content {
  background: url(/images/interface/body-wht.png) top left repeat-y;
  margin: 0;
}
`;