import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
// @ts-ignore
import { anchors } from '../css/anchors.css.ts';
// @ts-ignore
import { navStyles } from '../css/nav.css.ts';

const getTargetPath = currentPagePath => {
  const pathSegments = currentPagePath.split('/');
  const startOfPagination = pathSegments.indexOf('pagination');
  const sliceTarget = startOfPagination === -1 ? pathSegments.length : startOfPagination;

  return pathSegments.slice(0, sliceTarget).join('/');
}

const firstPage = (currentPagePath, currentPageNumber, pages, contentType) => {  
  const page = pages[0];
  const paginationPath = `${getTargetPath(currentPagePath)}/pagination/${page}`
  const url = `${paginationPath}`;
  
  return currentPageNumber >= 3 ? html`<li><a href="${url}">First Page</a></li>` : '';
};

const lastPage = (currentPagePath, currentPageNumber, pages, contentType) => {
  const page = pages[pages.length - 1];
  const paginationPath = `${getTargetPath(currentPagePath)}/pagination/${page}`;
  const url = `${paginationPath}`;
  
  return currentPageNumber < pages.length - 1 ? html`<li><a href="${url}">Last Page</a></li>` : '';
}

const nextPage = (currentPagePath, currentPageNumber, pages, contentType) => {
  const page = pages[currentPageNumber];
  const paginationPath = `${getTargetPath(currentPagePath)}/pagination/${page}`;
  const url = `${paginationPath}`;

  return currentPageNumber < pages.length - 1 ? html`<li><a href="${url}">></a></li>` : '';
}

const prevPage = (currentPagePath, currentPageNumber, pages, contentType) => {
  const page = pages[currentPageNumber - 2];
  const paginationPath = `${getTargetPath(currentPagePath)}/pagination/${page}`;
  const url = `${paginationPath}`;
  
  return currentPageNumber > 2 ? html`<li><a href="${url}"><</a></li>` : '';
}

const flatten = p => p[0];

const selectPages = (currentPage: number, pages:number[]) => {
  const numPages = pages.length;

  if (numPages <= 3) {
    return pages;
  }

  if (numPages === currentPage) {
    return pages.slice(-3);
  }

  if ((numPages >= 3) && (currentPage <= 2)) {
    return pages.slice(0, 3);
  }

  return pages.slice(currentPage - 2, currentPage + 1)
};

const listItemUrl = (p, path, contentType) => { 
  const currentPage = path.split('/').find(s => s.startsWith('page-')) || "page-1";
  const url = `${getTargetPath(path)}/pagination/${p}`;
  const isActive = p === currentPage ? "active" : "";
  
  return html`<li><a href="${url}" class="${isActive}">${Number(p.replace('page-', ''))}</a></li>`;
}

@customElement('nav-pagination')
export class NavPagination extends LitElement {
  @property() pages = '';
  @property() currentPage = '';
  @property() currentPath = '';
  @property() contentType = '';

  static get styles() {
    return [
      anchors,
      navStyles,
      css`
        ul {
          display: flex;
          list-style: none;
          margin: 0;
        }

        li {
          padding: 1em 1em 1em 0;
        }

        a {
          color: var(--red);
          text-transform: none;
        }

        .active {
          font-weight: 700;
        }
      `
    ];
  }

  render() {
    const pages = JSON.parse(this.pages);
    const pagesArray = Object.entries(pages)
    const currentPageNumber = Number(JSON.parse(this.currentPage));
    const currentPagePath = this.currentPath;

    if (!Boolean(pages) || pagesArray.length <= 1) {
      return html``;
    }

    const pageList = pagesArray.map(flatten).sort(new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'}).compare);
    const paginationOptions = selectPages(currentPageNumber, pageList).map(p => listItemUrl(p, currentPagePath, this.contentType));

    return html`
      <ul>
        ${firstPage(currentPagePath, currentPageNumber, pageList, this.contentType)}
        ${prevPage(currentPagePath, currentPageNumber, pageList, this.contentType)}
        ${[paginationOptions]}
        ${nextPage(currentPagePath, currentPageNumber, pageList, this.contentType)}
        ${lastPage(currentPagePath, currentPageNumber, pageList, this.contentType)}
      </ul>
    `
  }
}