import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './NavList';

@customElement('nav-primary')
export class NavPrimary extends LitElement {
  // pageContent is an object which gets passed in stringified
  @property({type: String}) pageContext = '';
  
  static get styles() {
    return css`
      :host {
        background: rgba(255,255,255,.7);
        display: block;
        flex-grow: 1;
        border-bottom: 2px solid #444;
        border-top: 2px solid #444;
        width: 100%;
      }

      nav {
        margin: 0 auto;
        width: 100%;
      }
    `
  }
    
  render() {
    const pageContext = JSON.parse(this.pageContext);

    return html`
        <nav>
          <nav-list
            currentPath=${pageContext.path}
            pathName=${pageContext.pathName}
            routes=${JSON.stringify(pageContext.routes)}
          ></nav-list>
        </nav>
    `;
  }
}
